import React, { useState } from "react";
import styled from "styled-components";
import { PageTitle } from "../ReusableItems/reusableItems";
import Layout from "../Layout";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const Wrpper = styled.section`
  display: flex;
  background: white;
  justify-content: center;
  padding: 0% 10% 5% 10%;
  @media (max-width: 780px) {
    padding: 0% 5% 10% 5%;
  }
`;
const Card = styled.section`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.75);
  @media (max-width: 780px) {
    flex-direction: column;
    width: 100%;
  }
`;
const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  text-align: left;
  padding: 5%;
  width: 65%;
  @media (max-width: 780px) {
    width: 90%;
  }
`;
const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  background: #333333;
  padding: 5%;
  width: 35%;
  color: white;
  @media (max-width: 780px) {
    width: 90%;
  }
`;
const Heading2 = styled.h1`
  font-size: 26px;
  line-height: 28px;
  text-align: center;
  margin: 0;
  font-weight: 100;
  font-family: "PT Sans", sans-serif;
`;
const Heading3 = styled.h1`
  font-size: 16px;
  line-height: 16px;
  margin: 10% 5% 10% 0%;
  font-weight: 100;
  font-family: "PT Sans", sans-serif;
`;
const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 8%;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const FormLabel = styled.label`
  opacity: 0.4;
  font-size: 14px;
`;
const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
const Input = styled.input`
  outline: 0;
  border: 0;
  padding: 0px 5px 15px 5px;
  width: 250px;
  margin-bottom: 20px;
  background: transparent;
  border-bottom: ${({ error }) =>
    error ? "1px solid red" : "1px solid lightgray"};
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  margin-top: -18px;
`;
const SubmitResult = styled.p`
  color: ${({ success }) => (success ? "green" : "red")};
  font-size: 16px;
  align-text: center;
  marign-bottom: 1%;
`;
const HR = styled.hr`
  width: 100%;
  border: 0;
  border-bottom: 1px solid lightgray;
`;
const Icon = styled.button`
  border: 0;
  outline: 0;
  border-radius: 50%;
  width: 65px;
  height: 60px;
  background: #292929;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292929;
  transition: 0.5s;
  &:hover {
    filter: invert(1);
    color: white;
    cursor: pointer;
  }
`;

const initialFields = {
  name: "",
  email: "",
  phone: "",
  company: "",
  message: "",
};
const initialErrors = {
  name: false,
  email: false,
  phone: false,
  company: false,
  message: false,
};
const errorMessages = {
  name: "Name is required!",
  email: "Email is required!",
  phone: "Phone is required!",
  company: "Company is required!",
  message: "Message is required!",
};

const ContactUs = () => {
  const [errors, setErrors] = useState(initialErrors);
  const [fields, setFields] = useState(initialFields);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState({
    success: true,
    message: "",
  });
  var isDisabled = isSubmitting;

  const validateForm = () => {
    let valide = true;
    Object.keys(fields).map((name) => {
      if (!fields[name] || fields[name] === "") {
        console.log("name", name);
        valide = false;
        setErrors({
          ...errors,
          [name]: true,
        });
        console.log("errors", errors);
      }
    });
    return valide;
  };

  const onInputFieldsChage = (e) => {
    setSubmitResult({ success: true, message: "" });
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: !!(!e.target.value || e.target.value === ""),
    });
  };

  const onClickSubmit = (e) => {
    setIsSubmitting(true);
    setSubmitResult({ success: true, message: "" });
    if (validateForm()) {
      axios
        .post(`http://localhost:3001/api/contact/send-message`, fields)
        .then((res) => {
          console.log(res);
          console.log(res.data);
          setFields(initialFields);
          setErrors(initialErrors);
          setSubmitResult({
            success: true,
            message: "Message submitted successfully!",
          });
          setIsSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          setSubmitResult({
            success: false,
            message: "Something went wrong! check your internet connection.",
          });
          setIsSubmitting(false);
        });
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <Layout variant="white">
      <PageTitle>CONTACT US</PageTitle>
      <Wrpper>
        <Card>
          <FormSection>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Heading2>Send us a Message</Heading2>
              <img
                src="send-email-icon.png"
                alt="send-email-icon"
                width="60px"
                style={{ opacity: "0.35" }}
              />
            </div>
            <Form>
              <InputDiv>
                <FormLabel>Your Name</FormLabel>
                <Input
                  disabled={isDisabled}
                  name="name"
                  type="text"
                  required
                  placeholder=""
                  onChange={onInputFieldsChage}
                  error={errors.name}
                />
                {errors.name && (
                  <ErrorMessage>{errorMessages.name}</ErrorMessage>
                )}
              </InputDiv>
              <InputDiv>
                <FormLabel>Email Address</FormLabel>
                <Input
                  disabled={isDisabled}
                  name="email"
                  type="text"
                  required
                  placeholder=""
                  onChange={onInputFieldsChage}
                  error={errors.email}
                />
                {errors.email && (
                  <ErrorMessage>{errorMessages.email}</ErrorMessage>
                )}
              </InputDiv>
              <InputDiv>
                <FormLabel>Phone</FormLabel>
                <Input
                  disabled={isDisabled}
                  name="phone"
                  type="number"
                  required
                  placeholder=""
                  onChange={onInputFieldsChage}
                  error={errors.phone}
                />
                {errors.phone && (
                  <ErrorMessage>{errorMessages.phone}</ErrorMessage>
                )}
              </InputDiv>
              <InputDiv>
                <FormLabel>Company</FormLabel>
                <Input
                  disabled={isDisabled}
                  name="company"
                  type="text"
                  required
                  placeholder=""
                  onChange={onInputFieldsChage}
                  error={errors.company}
                />
                {errors.company && (
                  <ErrorMessage>{errorMessages.company}</ErrorMessage>
                )}
              </InputDiv>
              <InputDiv>
                <FormLabel>Message</FormLabel>
                <Input
                  disabled={isDisabled}
                  name="message"
                  type="text"
                  required
                  placeholder=""
                  onChange={onInputFieldsChage}
                  error={errors.message}
                />
                {errors.message && (
                  <ErrorMessage>{errorMessages.message}</ErrorMessage>
                )}
              </InputDiv>
            </Form>
            <br />
            {submitResult && submitResult !== "" && (
              <SubmitResult success={submitResult.success}>
                {submitResult.message}
              </SubmitResult>
            )}
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "5%",
              }}
            >
              <HR />
              <Icon disabled={isSubmitting} onClick={onClickSubmit}>
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="light"
                  />
                ) : (
                  <img
                    src="submit-icon.png"
                    alt="submit-icon"
                    width="24px"
                    height="24px"
                  />
                )}
              </Icon>
            </div>
          </FormSection>
          <ContactSection>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "60px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Heading2>Contact Information</Heading2>
            </div>
            <div style={{ marginTop: "15%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src="location-icon.png"
                  alt="location-icon"
                  width="30px"
                  style={{ opacity: "0.6", marginRight: "12px" }}
                />
                <Heading3>360 King Street</Heading3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src="phone-icon.png"
                  alt="location-icon"
                  width="30px"
                  style={{ opacity: "0.6", marginRight: "12px" }}
                />
                <Heading3>(800) 900 200 300</Heading3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  src="email-icon.png"
                  alt="location-icon"
                  width="30px"
                  style={{ opacity: "0.6", marginRight: "12px" }}
                />
                <Heading3>info.techlabz.com</Heading3>
              </div>
            </div>
          </ContactSection>
        </Card>
      </Wrpper>
    </Layout>
  );
};

export default ContactUs;
