import styled from 'styled-components';

export const PageTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 80px;
  height: 280px;
  color: black;
  opacity: 0.8;
  margin-top: 70px;
  padding-left: 5%;
  padding-right: 5%;
  background: white;
  font-family: 'PT Sans', sans-serif;
  @media (max-width: 780px) {
    height: 200px;
    font-size: 60px;
    padding-top: 25px;
  }
`;