import React, {useState} from 'react';
import styled from "styled-components";


const Panel = styled.section`
    &.show {
        display: block;
    }
    &.hide {
        display: none
    }
`;
const Arrow = styled.hr`
border: solid black;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
transform: rotate(-135deg);
-webkit-transform: rotate(-135deg);
`;
const ArrowDown = styled.hr`
border: solid black;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
`;
const Heading5 = styled.div`
   transition: all 0.2s ease;
    font-size: 19px;
    line-height: 1.4em;
    font-weight: 700;
    text-align:left;
    position: relative;
    display: flex;
    padding-top: 24px;
    padding-bottom: 24px;
    cursor: pointer;

    &:after {
        transition: all 0.2s ease;
        content: '';
        border: solid black;
border-width: 0 2px 2px 0;
display: inline-block;
padding: 3px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
position: absolute;
right: 10px;
top: 32px;
    }

    &.active {
        transition: all 0.2s ease;
        &:after {
            transition: all 0.2s ease;
            transform: rotate(-135deg);
-webkit-transform: rotate(-135deg);
        }
    }
`;
const PanelPara = styled.p`
margin-bottom: 0;
    padding-bottom: 16px;
    border-bottom: 1px none #ececec;
    color: #444;
    font-weight: 500;
    line-height: 1.5em;
text-align:start;
display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
`;
const HR = styled.hr`
  width: 100%;
  border: 0;
  border-bottom: 1px solid lightgray;

`;
const Collapse = (props) => {
    const { heading, body } = props;
    const [showCollapse, setShowCollapse] = useState(false);
    return (
 <>
 
 <Heading5 className={`${showCollapse && 'active'}`} onClick={() => setShowCollapse(!showCollapse)}>{heading}</Heading5>

 <Panel className={`${showCollapse ? 'show' : 'hide'}`}>
   <PanelPara> {props.children} 
   {/* <HR/> */}
   </PanelPara>
   
 </Panel>

 </>
    );
  }
  
  export default Collapse;