import React, {useState} from 'react';
import styled from "styled-components";
import Layout from '../Layout';
import Collapse from './Collapse';

const Container = styled.section`
position: relative;
top: 0;
bottom: 0;
display: flex;
margin-top: 0;
padding-top: 144px;
padding-bottom: 80px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
flex-direction: column;
-webkit-box-pack: start;
justify-content: flex-start;
-webkit-box-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
max-width: 941px;
&:before {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
`;
const Wrapper1 = styled.section`
display: block;
width: 100%;
max-width: 960px;
margin-top: 0;
font-family: 'TT Norms Pro Bold', sans-serif;
text-align: left;
`;
const Wrapper2 = styled.section`
display: block;
width: 100%;
margin-top: 100px;
margin-bottom:30px;
font-family: 'TT Norms Pro Bold', sans-serif;
text-align: center;
`;
const Wrapper3= styled.section`
display: block;
width: 100%;
margin-top: 100px;
margin-bottom:60px;
font-family: 'TT Norms Pro Bold', sans-serif;

`;
const ImageSection = styled.div`
display: block;
width: 100%;
font-family: 'TT Norms Pro Bold', sans-serif;
text
`;
const Paragraph = styled.p`
font-size: 19px;
margin: 0;
font-family: 'TT Norms Pro Bold', sans-serif;
color: #333333;
font-weight: 500;
text-align:center;
line-height: 28px;
box-sizing: border-box;
margin-bottom: 32px;
`;
const Heading1 = styled.h1`
font-size: 48px;
margin: 0;
font-family: 'TT Norms Pro Bold', sans-serif;
color: #333333;
line-height: 64px;
font-weight: 500;
margin-bottom: 24px;
display:flex;
justify-content:center;
`;
const Heading2 = styled.h1`
margin-top: 80px;
margin-bottom: 24px;
font-size: 47px;
line-height: 1.4em;
font-weight: 700;
text-align: left;
text-transform: none;
opacity: 0.6;
`;
const Tag = styled.p`
color: #616161;
line-height: 1.5em;
font-size: 16px;
font-family: 'TT Norms Pro ExtraBold', sans-serif;
`;
const Anchor = styled.a`
font-size: 16px;
color: black;
text-decoration: none;
`;
const BodyHeading = styled.p`
margin-top: 80px;
margin-bottom: 24px;
opacity: 0.6;
font-size: 47px;
line-height: 1.4em;
font-weight: 700;
text-align: left;
text-transform: none;
`;
const BodyText = styled.p`
font-size: 16px;  
margin-bottom: 24px;
line-height: 1.5em;
font-family: 'TT Norms Pro Medium', sans-serif;
color: #616161;
`;
const Heading3 = styled.h3`
margin-top: 0;
margin-bottom: 32px;
color: #777;
font-size: 24px;
line-height: 32px;
text-align: center;
font-family: TTNorms,sans-serif;
`;
const ListHeading = styled.p`
font-size: 16px;  
color: #616161;
line-height: 1.5em;
font-weight: 700;
`;
const OrderedList = styled.ol`

`;
const ListItem = styled.li`
font-size: 16px; 
line-height: 1.5em;
font-family: 'TT Norms Pro Medium', sans-serif;
color: #616161;
`;
const UnorderedList = styled.ul`
font-size: 15px;
`;
const StrongText = styled.strong`
font-weight: 900;
font-family: 'TT Norms Pro ExtraBold', sans-serif;
`;
const Heading5 = styled.h5`
font-size: 20px;
line-height: 22px;
margin: 0;
font-weight: 300;
`;
const Button = styled.button`
outline: none !important;
background-color: rgb(255, 255, 255);
flex: 0 240px;
border: 1px solid #492f5c;
border-radius:5px;
line-height: 24px;
font-weight: 700;
text-align: center;
color: rgb(53, 29, 72);
padding-right: 24px;
padding-left: 24px;
border-style: solid;
transition-property: none;
padding: 12px 24px;
max-width: 100%;
display: inline-block;
transition: opacity .2s ease;
font-size: 16px;
box-sizing: border-box;
float: left; 
background-color: ${props => (props.selected ? 'rgb(53, 29, 72)' : 'none')};
color: ${props => (props.selected ? 'white' : 'none')};
&:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
&:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
`;

const ButtonsRow = styled.div`
display: flex;
justify-content: center;



}
`;
const ImgPara = styled.image`
font-size: 16px;  
color: #616161;
line-height: 1.5em;
font-weight: 700;
margin-right: 32px;
margin-left: 32px;
max-height: 40px;
`;
const TopNotch = styled.h1`
margin-top: 0;
margin-bottom: 0px;
color: #351d48;
font-size: 48px;
line-height: 64px;
text-align: center;
`;
const MainWrapper = styled.div`
max-width: 1232px;
margin-right: auto;
margin-left: auto;
margin-bottom:100px;
}
`;
const Wrapper4 = styled.section`
display: grid;
grid-auto-columns: 1fr;
grid-template-rows: auto auto;
grid-column-gap: 48px;
grid-row-gap: 48px;
grid-template-columns: 1fr 1fr 1fr;
font-family: TTNorms,sans-serif;
color: #1a1b1f;
font-size: 16px;
line-height: 28px;
font-weight: 700;
}
`;
const PricingFeature = styled.div`
font-family: TTNorms,sans-serif;
color: #1a1b1f;
font-size: 16px;
line-height: 28px;
font-weight: 700;
}
`;
const ImgLogo = styled.div`

margin-bottom: 16px;
display: block;
border-color: transparent;
border: 0;
max-width: 100%;
text-align:left;


`;
const Heading7 = styled.h1`
margin-top: 0;
margin-bottom: 8px;
color: #351d48;
font-size: 19px;
line-height: 28px;
text-align: left;
font-weight: 700; 
`;
const ParaPricing = styled.p`
color: #616161;
line-height: 1.5em;
font-weight: 500;
margin-top: 0;
display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
text-align:start;
`;
const Wrapper5 = styled.section`
display: block;
width: 100%;

margin-top: 0;
font-family: 'TT Norms Pro Bold', sans-serif;
text-align: left;
margin-bottom:200px;
text-align:center;
`;
const HR = styled.hr`
width: 55%;
border: 0;
border-bottom: 1px solid lightgray;
margin-top:64px;
margin-bottom:24px;
`;
const Arrow = styled.hr`
border: solid black;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
transform: rotate(-135deg);
-webkit-transform: rotate(-135deg);
`;
const ArrowDown = styled.hr`
border: solid black;
border-width: 0 3px 3px 0;
display: inline-block;
padding: 3px;
transform: rotate(45deg);
-webkit-transform: rotate(45deg);
`;
const PricingWrapper = styled.section`
width: 55%;
max-width: 1232px;
margin-right: auto;
margin-left: auto;
font-family: TTNorms,sans-serif;
color: #1a1b1f;
font-size: 16px;
line-height: 28px;
font-weight: 700;
text-align: left;
margin-bottom:100px;
box-sizing: border-box;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const PanelPara = styled.p`
margin-bottom: 0;
padding-bottom: 16px;
border-bottom: 1px none #ececec;
color: #444;
font-weight: 500;
line-height: 1.5em;
text-align:start;
display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
`;
const PricingPara1 = styled.p`
margin-bottom: 0;
padding-bottom: 16px;

color: #444;
font-weight: 500;
line-height: 1.5em;
display: block;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
`;
const PricingPara2 = styled.p`
margin-top: 16px;
margin-bottom: 0;
padding-bottom: 16px;

color: #351d48;
font-weight: 700;
font-size: 16px;
`;
const PricingRow = styled.div`
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
max-width: 720px;
margin-right: auto;
margin-left: auto;


cursor: auto;
`;
const PricingCell = styled.div`
padding-top: 12px;
padding-bottom: 16px;
align-self: stretch;
-webkit-box-flex: 1;
flex: 1;

`;
const PricingType = styled.div`
color: #444;
line-height: 24px;
font-weight: 500;
`;
const PricingPlan = styled.div`
color: #777;
font-size: 13px;
line-height: 18px;
font-weight: 500;
`;
const PricingSpec = styled.div`
margin-top: 12px;
color: #444;
line-height: 24px;
font-weight: 500;
text-align: right;
`;
const PricingFaq = styled.div`
margin-bottom: 0;
padding-bottom: 16px;
border-bottom: 1px none #ececec;
color: #444;
font-weight: 500;
line-height: 1.5em;
`;
const HR2 = styled.hr`
width: 100%;
border: 0;
border-bottom: 1px solid lightgray;

`;
const PricingSection = styled.section`
background-image: linear-gradient(180deg,#f8f8f8,#fff);
margin-bottom: 0;
padding: 64px 16px;
margin-top: 2px;
padding-top: 96px;
padding-bottom: 96px;
background-color: #f8f8f8;
`;
const PricingWrapper1 = styled.div`
max-width: 1232px;
margin-right: auto;
margin-left: auto;
`;
const PricingHeadline = styled.div`
color: #351d48;
font-size: 48px;
line-height: 64px;
text-align: center;
margin-bottom: 24px;
font-weight: 700;
`;
const PricingCta = styled.p`
margin-bottom: 32px;
color: #351d48;
font-size: 19px;
line-height: 28px;
text-align: center;

`;
const Pricingbtn = styled.a`
display: block;
width: 240px;
margin-right: auto;
margin-bottom: 32px;
margin-left: auto;
padding-top: 16px;
padding-bottom: 16px;
border-radius: 4px;
background-color: #760fc3;
font-size: 19px;
line-height: 28px;
text-align: center;
padding: 9px 15px;
color: #fff;
text-decoration: none;

`;
const PricingCta1 = styled.p`
margin-bottom: 24px;
color: #616161;
line-height: 1.5em;
font-weight: 500;
text-align: center;

`;
const PricingSection1 = styled.section`
margin-top: 0;
margin-bottom: 0;
padding: 64px 16px;
padding-top: 0;
padding-bottom: 0;
`;
const PricingSection10 = styled.section`
margin-top: 0;
margin-bottom: 0;
padding: 64px 16px;
padding-top: 0;
padding-bottom: 0;
@media (max-width: 942px) {
  flex-direction: column;
}
`;
const PricingWrapper10 = styled.div`
max-width: 1232px;
margin-right: auto;
margin-left: auto;
@media (max-width: 942px) {
  flex-direction: column;
}
`;
const PricingContainer = styled.div`
display: block;
@media (max-width: 942px) {


}
`;
const PricingLayout = styled.div`
display: grid;
grid-auto-columns: 1fr;
grid-column-gap: 0;
grid-row-gap: 0;
grid-template-areas: ".";
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
grid-template-rows: auto auto auto auto auto auto;
color: #351d48;
@media (max-width: 942px) {
  flex-direction: column;
}
`;
const PricingNode= styled.div`
width: 260px;
padding-right: 24px;
grid-row-end: span 5;
grid-row-start: span 5;
grid-column-end: span 1;
grid-column-start: span 1;
color: #351d48;
`;
const PricingLead= styled.h5`
margin-top: 0;
margin-bottom: 24px;
color: #351d48;
font-size: 32px;
line-height: 40px;
text-align: left;
font-weight: 700;
`;
const PricingDes = styled.div`
margin-bottom: 24px;
line-height: 24px;
font-weight: 400;
text-align: left;
`;
const PricingSwitcher = styled.div`
display: flex;
`;
const PricingLabel = styled.div`
color: #616161;
line-height: 24px;
`;
const PricingToggle = styled.div`
width: 40px;
height: 24px;
margin-right: 8px;
margin-left: 8px;
padding-right: 2px;
padding-left: 2px;
border-radius: 40px;
background-color: #6b25bc;
cursor: pointer;
`;
const PricingNob = styled.div`
width: 20px;
height: 20px;
margin-top: 2px;
margin-left: ${props => (props.package == "monthly" ? '16px' : '32px')};
border-radius: 40px;
background-color: #fff;
color: #fff;
transform: translate3d(-16px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
transform-style: preserve-3d;

}
`;
const PricingSwitcherLable = styled.div`
line-height: 24px;
color: rgb(97, 97, 97);

}
`;
const PricingNode1 = styled.div`
border-top-style: solid;
border-bottom-style: solid;
border-left-style: solid;
border-right-style: solid;

border-top-left-radius: 12px;
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;

border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingPlanHeader = styled.div`
margin-top: 12px;
margin-bottom: 12px;
color: #777;
font-size: 10px;
line-height: 16px;
font-weight: 700;
text-transform: uppercase;
text-align: center;
`;
const PricingNode2 = styled.div`
grid-column-end: span 3;
grid-column-start: span 3;
grid-row-end: span 1;
grid-row-start: span 1;
border-right-style: solid;
border-top-left-radius: 0;
border-top-right-radius: 12px;
border-top-style: solid;
border-bottom-style: solid;
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;

border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingNode3 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingPlanHeader2 = styled.div`
margin-top: 24px;
margin-bottom: 24px;
color: #351d48;
line-height: 24px;
text-align: center;
font-size: 19px;
font-weight: 700;
`;
const PricingNode4 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingPlanLarge = styled.div`
height: 48px;
margin-top: 20px;
margin-bottom: 4px;
padding-bottom: 0;
font-size: 40px;
line-height: 48px;
`;
const PricingPlanYearly = styled.div`
font-size: 40px;
line-height: 48px;
text-align: center;
`;
const PricingPlanSmall= styled.div`
color: #616161;
font-size: 13px;
line-height: 16px;
font-weight: 400;
text-align: center;
`;
const PricingPlanSmall1= styled.div`
color: #616161;
font-size: 13px;
line-height: 16px;
font-weight: 400;
text-align: center;
`;
const PricingNode5 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingPlanLarge1 = styled.div`
margin-top: 4px;
margin-bottom: 4px;
padding-bottom: 0;
font-size: 40px;
line-height: 48px;
`;
const PricingNode6 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingNode7 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingNodebtn = styled.div`
width: 100%;
margin-top: 24px;
margin-bottom: 24px;
padding: 8px 16px;
border-radius: 4px;
background-color: #760fc3;
color: #fff;
text-align: center;
display: inline-block;
border: 0;
line-height: inherit;
text-decoration: none;
cursor: pointer;
-webkit-box-flex: 1;
color: #760fc3;
font-size: 16px;
transition: opacity .2s ease;
flex: 1;
`;
const PricingNode8 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingNode9 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingInfo = styled.div`
display: inline;
margin-bottom: 16px;
line-height: 24px;
font-weight: 400;
text-align: center;
`;
const PricingDivider = styled.div`
display: none;
`;
const PricingNode10 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingNode11 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingMeta = styled.div`
display: flex;
padding-right: 0;
padding-left: 0;
border: 1px #ececec;
text-align: center;
`;
const PricingMetaHeader = styled.div`
margin-top: 16px;
margin-bottom: 16px;
color: #777;
font-size: 12px;
line-height: 16px;
text-transform: uppercase;
text-align: center;
`;
const PricingNode12 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingMeta1 = styled.div`
display: flex;
padding-right: 0;
padding-left: 0;
border: 1px #ececec;
text-align: center;
}
`;
const PricingMetaHeader1 = styled.div`
margin-top: 16px;
margin-right: 4px;
margin-bottom: 16px;
line-height: 24px;
font-weight: 700;
text-align: left;
`;
const MetaInfo = styled.div`
position: relative;
width: 24px;
height: 24px;
margin-top: 16px;
margin-bottom: 16px;
text-align: center;
`;
const MetaIcon = styled.div`
position: relative;
display: inline-block;
width: 24px;
height: 24px;
opacity: 0.64;
&:hover {
  &+.tooltips {
    display: block;
  }
}
`;
const MetaTooltip = styled.div`
width: 240px;
margin-bottom: 4px;
padding: 8px;
border-radius: 8px;
background-color: #351d48;
color: #fff;
text-align: left;
position: absolute;
left: -110px;
top: auto;
bottom: 100%;
z-index: 1;
display: none;
`;
const MetaTooltipText = styled.div`
font-size: 13px;
line-height: 20px;
font-weight: 400;
color: #fff;
text-align: left;
`;
const PricingNode13 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingValue = styled.div`
margin-top: 16px;
margin-bottom: 16px;
line-height: 24px;
font-weight: 500;
text-align: center;
display: block;
`;
const PricingNode14 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;

const MetaInfo1 = styled.div`
position: relative;
width: 24px;
height: 24px;
margin-top: 16px;
margin-bottom: 16px;
text-align: center;
`;
const MetaIcon1 = styled.div`
position: relative;
display: inline-block;
width: 24px;
height: 24px;
opacity: 0.64;
&:hover {
  &+.tooltips {
    display: block;
  }
}
`;
const MetaTooltip1 = styled.div`
width: 240px;
margin-bottom: 4px;
padding: 8px;
border-radius: 8px;
background-color: #351d48;
color: #fff;
text-align: left;
position: absolute;
left: -100px;
top: auto;
bottom: 100%;
z-index: 1;
display: none;
`;
const MetaTooltipText1 = styled.div`
font-size: 13px;
line-height: 20px;
font-weight: 400;
color: #fff;
text-align: left;
`;
const PricingNode15 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingValue1 = styled.div`
margin-top: 16px;
margin-bottom: 16px;
line-height: 24px;
font-weight: 500;
text-align: center;
&:hover {
  &+.tooltips {
    display: block;
  }
}
`;
const MetaTooltip2 = styled.div`
font-size: 13px;
line-height: 20px;
font-weight: 400;
color: #fff;
text-align: left;
z-index: 1;
margin-bottom: 4px;
padding: 8px;
border-radius: 8px;
background-color: #351d48;
color: #fff;
display: none;
position:absolute;
left: 8px;
top: -24px;
right: 0;
bottom: auto;

width: 224px;
text-align: center;
`;
const MetaTooltipText2 = styled.div`
font-size: 13px;
line-height: 20px;
font-weight: 400;
text-align: center;

`;
const PricingPlanP = styled.span`
border-bottom: 1px dotted #000;
cursor: pointer;
line-height: 24px;
font-weight: 500;
text-align: center;
position: relative;

}  
`;
const PricingNode16 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingValueIcon = styled.div`
width: 24px;
height: 24px;
margin-top: 16px;
margin-right: auto;
margin-left: auto;
padding-bottom: 16px;
text-align: center;
`;
const PricingNode17 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid solid solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingNode18 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid solid solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;



const PricingTabContainer = styled.div`
@media (max-width: 942px) {
  flex-direction: column;
}

`;
const PricingTabGrid = styled.div`
grid-template-rows: auto auto auto auto auto auto;
color: #351d48;
grid-template-columns: 1fr 1fr 1fr;
display: grid;
grid-auto-columns: 1fr;
grid-column-gap: 0;
grid-row-gap: 0;
grid-template-areas: ".";
`;
const PricingNode20 = styled.div`
grid-row-start: span 5;
grid-row-end: span 5;
grid-column-start: span 1;
grid-column-end: span 1;
width: 260px;
padding-right: 24px;
`;
const PricingTabInfo = styled.div`
margin-bottom: 24px;
line-height: 24px;
font-weight: 400;
text-align: left;
`;
const PrivateHeading = styled.h5`
margin-top: 0;
margin-bottom: 24px;
color: #351d48;
font-size: 32px;
line-height: 40px;
text-align: left;
`;
const PricingNode21 = styled.div`
grid-column-end: span 2;
grid-column-start: span 2;
grid-row-end: span 1;
grid-row-start: span 1;
border-style: solid;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
border-width: 1px;
border-color: #ececec;
text-align: center;
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
`;
const PricingTabHeader = styled.div`
margin-top: 12px;
margin-bottom: 12px;
color: #777;
font-size: 10px;
line-height: 16px;
font-weight: 700;
text-transform: uppercase;
text-align: center;
@media (max-width: 942px) {
  flex-direction: column;
}
`;
const PricingNode22 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingTabHeader1 = styled.div`
margin-top: 24px;
margin-bottom: 24px;
color: #351d48;
line-height: 24px;
text-align: center;
font-size: 19px;
font-weight: 700;
`;
const PricingNode23 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingNode24 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none none none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingTabs = styled.div`
color: #616161;
font-size: 13px;
line-height: 16px;
font-weight: 400;
text-align: center;
`;
const PricingTabl = styled.div`
height: 48px;
margin-top: 20px;
margin-bottom: 4px;
padding-bottom: 0;
font-size: 40px;
line-height: 48px;
text-align: center;
`;
const PricingNode25 = styled.div`
position: relative;
display: block;
padding-right: 24px;
padding-left: 24px;
border-style: none solid none solid;
border-width: 1px;
border-color: #ececec;
text-align: center;
`;
const PricingTabAnchor = styled.div`
background-color: #0d83cd;
color: #fff;
text-align: center;
width: 100%;
margin-top: 24px;
margin-bottom: 24px;
padding: 8px 16px;
border-radius: 4px;
`;
const PricingPlanInfo = styled.div`
display: inline;
margin-bottom: 16px;
line-height: 24px;
font-weight: 400;
text-align: center;
`;
const PricingPlanMeta = styled.div`
display: inline;
margin-bottom: 16px;
line-height: 24px;
font-weight: 400;
text-align: center;
`;
const PricingPlanMeta1 = styled.div`
display: flex;
padding-right: 0;
padding-left: 0;
border: 1px #ececec;
text-align: center;
`;
const PricingPlanMetaLabel = styled.div`
margin-top: 16px;
margin-right: 4px;
margin-bottom: 16px;
line-height: 24px;
font-weight: 700;
text-align: left;
`;
const PricingValueIcon1 = styled.div`
width: 24px;
height: 24px;
margin-top: 16px;
margin-right: auto;
margin-left: auto;
padding-bottom: 16px;
text-align: center;
`;
const PricingPlanInfo1 = styled.div`
margin-top: 16px;
margin-bottom: 16px;
line-height: 24px;
font-weight: 500;
text-align: center;
`;



const Pricing = () => {
  const [state, setState] = useState({
    first: true,
    third:false, 
    pricing_package: "monthly"
  });
  
  const {first,third, pricing_package}= state;
  
  const toggle = buttonName => {
    setState({...state, visible : true})
    setState(prev => ({ [buttonName]: !prev[buttonName] }));
  };
  const handleTogglePackage = () => {
    if (pricing_package == "monthly") {
      setState({...state, pricing_package: "yearly"});
    } else {
      setState({...state, pricing_package: "monthly"});
    }
  }
  
  
  return (
    <Layout>
    <Container>
    <Wrapper1>
    <Heading1>Deliver amazing mobile apps, faster</Heading1>
    <Paragraph>Trial any plan for 14 days, or build for free on our Hobby tier</Paragraph>
    <ButtonsRow>
    <Button selected={first} onClick={() => toggle('first')}>Public Cloud Plans</Button>
    <Button selected={third} onClick={() => toggle('third')}>Private Cloud Plans</Button>
    </ButtonsRow>
    </Wrapper1>
    </Container>
    <PricingSection10>
    <PricingWrapper10>
    
    {
      first &&
      <PricingContainer> 
    <PricingLayout>
    
    <PricingNode>
    <PricingLead>Public Cloud Plans</PricingLead>
    <PricingDes>Build in our secure, scalable public cloud instances.</PricingDes>
    
    <PricingSwitcher>
    <PricingLabel>Monthly</PricingLabel>
    
    <PricingToggle onClick={() => handleTogglePackage()} data-w-id="5d969387-9a72-6349-9dd8-55dd3a337ace">
    
    <PricingNob onClick={() => handleTogglePackage()} package={pricing_package} />
    </PricingToggle>
    < PricingSwitcherLable>
    Yearly <span style={{fontSize:"13px"}}>(Save 10%)</span>
    
    </PricingSwitcherLable>
    </PricingSwitcher>
    </PricingNode>
    
    <PricingNode1>
    <PricingPlanHeader>
    For Individuals
    </PricingPlanHeader>
    </PricingNode1>
    <PricingNode2>
    <PricingPlanHeader>
    FOR TEAMS AND ORGANIZATIONS
    </PricingPlanHeader>
    </PricingNode2>
    <PricingNode3>
    <PricingPlanHeader2>
    
    Solo Developer
    
    </PricingPlanHeader2>
    </PricingNode3>
    <PricingNode3>
    <PricingPlanHeader2>
    
    Org Standard
    </PricingPlanHeader2>
    </PricingNode3>
    <PricingNode3>
    <PricingPlanHeader2>
    
    Org Elite
    
    </PricingPlanHeader2>
    </PricingNode3>
    <PricingNode3>
    <PricingPlanHeader2>
    
    Velocity 🚀
    
    </PricingPlanHeader2>
    </PricingNode3>

{  
 pricing_package == "monthly" ?
    
    
    
    <>  

    <PricingNode4>
    <PricingPlanLarge>
    <PricingPlanYearly style={{display:"none"}}>
    $36
    </PricingPlanYearly>
    <PricingPlanYearly >
    $36
    </PricingPlanYearly>
    </PricingPlanLarge>
    <PricingPlanSmall>
    per month
    </PricingPlanSmall>
    </PricingNode4>
    <PricingNode5>
    <PricingPlanSmall1>
    Starts at
    </PricingPlanSmall1>
    <PricingPlanLarge1>
    
    <PricingPlanYearly style={{display:"none"}}>
    $90
    </PricingPlanYearly>
    <PricingPlanYearly >
    90
    </PricingPlanYearly>
    </PricingPlanLarge1>
    <PricingPlanSmall>
    per month
    </PricingPlanSmall>
    </PricingNode5>
    <PricingNode5>
    <PricingPlanSmall1>
    Starts at
    </PricingPlanSmall1>
    <PricingPlanLarge1>
    
    <PricingPlanYearly style={{display:"none"}}>
    $36
    </PricingPlanYearly>
    <PricingPlanYearly >
    $270
    </PricingPlanYearly>
    </PricingPlanLarge1>
    <PricingPlanSmall>
    per month
    </PricingPlanSmall>
    </PricingNode5>
    </>

:
<>
    <PricingNode4>
    <PricingPlanLarge>
    <PricingPlanYearly style={{display:"none"}}>
    $36
    </PricingPlanYearly>
    <PricingPlanYearly >
    $40
    </PricingPlanYearly>
    </PricingPlanLarge>
    <PricingPlanSmall>
    per month
    </PricingPlanSmall>
    </PricingNode4>
    <PricingNode5>
    <PricingPlanSmall1>
    Starts at
    </PricingPlanSmall1>
    <PricingPlanLarge1>
    
    <PricingPlanYearly style={{display:"none"}}>
    $90
    </PricingPlanYearly>
    <PricingPlanYearly >
    100
    </PricingPlanYearly>
    </PricingPlanLarge1>
    <PricingPlanSmall>
    per month
    </PricingPlanSmall>
    </PricingNode5>
    <PricingNode5>
    <PricingPlanSmall1>
    Starts at
    </PricingPlanSmall1>
    <PricingPlanLarge1>
    
    <PricingPlanYearly style={{display:"none"}}>
    $36
    </PricingPlanYearly>
    <PricingPlanYearly >
    $300
    </PricingPlanYearly>
    </PricingPlanLarge1>
    <PricingPlanSmall>
    per month
    </PricingPlanSmall>
    </PricingNode5>
    </>
}





    <PricingNode6>
    <PricingPlanLarge>
    <PricingPlanYearly style={{display:"none"}}>
    $36
    </PricingPlanYearly>
    <PricingPlanYearly >
    Custom
    </PricingPlanYearly>
    </PricingPlanLarge>
    <PricingPlanSmall>
    pricing
    </PricingPlanSmall>
    </PricingNode6>
    <PricingNode7>
    <PricingNodebtn>
    <a href="https://app.bitrise.io/users/sign_up" class="pricing-cta-large w-button" style={{color:"white",textDecoration:"none"}}>Try for free</a>
    </PricingNodebtn>
    </PricingNode7>
    <PricingNode7>
    <PricingNodebtn>
    <a href="https://app.bitrise.io/users/sign_up" class="pricing-cta-large w-button" style={{color:"white",textDecoration:"none"}}>Try for free</a>
    </PricingNodebtn>
    </PricingNode7>
    <PricingNode7>
    <PricingNodebtn>
    <a href="https://app.bitrise.io/users/sign_up" class="pricing-cta-large w-button" style={{color:"white",textDecoration:"none"}}>Try for free</a>
    </PricingNodebtn>
    </PricingNode7>
    <PricingNode8>
    <PricingNodebtn>
    <a href="https://app.bitrise.io/users/sign_up" class="pricing-cta-large w-button" style={{color:"white",textDecoration:"none"}}>Try for free</a>
    </PricingNodebtn>
    </PricingNode8>
    <PricingNode9>
    <PricingInfo>
    For professional indie mobile developers
    <PricingDivider>
    
    </PricingDivider>
    </PricingInfo>
    </PricingNode9>
    <PricingNode9>
    <PricingInfo>
    For multiple projects and team management
    <PricingDivider>
    
    </PricingDivider>
    </PricingInfo>
    </PricingNode9>
    <PricingNode9>
    <PricingInfo>
    For professional indie mobile developers
    <PricingDivider>
    
    </PricingDivider>
    </PricingInfo>
    </PricingNode9>
    <PricingNode10>
    <PricingInfo>
    Best performance available on public cloud
    <PricingDivider>
    
    </PricingDivider>
    </PricingInfo>
    </PricingNode10>
    <PricingMeta>
    <PricingMetaHeader>
    BUILDS
    </PricingMetaHeader>
    </PricingMeta>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode12>
    
    </PricingNode12>
    <PricingMeta1>
    <PricingMetaHeader1>
    Concurrencies
    </PricingMetaHeader1>
    <MetaInfo>
    <MetaIcon>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon>
    <MetaTooltip className='tooltips'>
    <MetaTooltipText>
    The number of builds that can run in parallel.
    <br/>
    </MetaTooltipText>
    </MetaTooltip>
    </MetaInfo>
    </PricingMeta1>
    <PricingNode13>
    <PricingValue>
    1
    </PricingValue>
    </PricingNode13>
    <PricingNode13>
    <PricingValue>
    From 2 to 10
    </PricingValue>
    </PricingNode13>
    <PricingNode13>
    <PricingValue>
    From 3 to 10
    </PricingValue>
    </PricingNode13>
    <PricingNode14>
    <PricingValue>
    Flexible
    </PricingValue>
    </PricingNode14>
    <PricingMeta1>
    <PricingMetaHeader1>
    Build timeout
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    Each build fails after a certain amount of time. 
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode13>
    <PricingValue>
    45 min / build
    </PricingValue>
    </PricingNode13>
    <PricingNode13>
    <PricingValue>
    90 min / build
    </PricingValue>
    </PricingNode13>
    <PricingNode13>
    <PricingValue>
    90 min / build
    </PricingValue>
    </PricingNode13>
    <PricingNode14>
    <PricingValue>
    Custom (up to 4h)
    </PricingValue>
    </PricingNode14>
    <PricingMeta1>
    <PricingMetaHeader1>
    Build machines
    
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    The types of virtual machines available to run builds on. 
    <br/>
    <strong>Click to compare machines</strong>
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    <PricingNode15>
    
    <PricingValue1>
    <PricingPlanP>
    Standard
    </PricingPlanP>
    </PricingValue1>
    <MetaTooltip2 className='tooltips'>
    <MetaTooltipText2>
    Click to compare build machines 
    <br/>
    
    
    </MetaTooltipText2>
    </MetaTooltip2>
    </PricingNode15>
    <PricingNode15>
    
    <PricingValue1>
    <PricingPlanP>
    Standard
    </PricingPlanP>
    </PricingValue1>
    <MetaTooltip2 className='tooltips'>
    <MetaTooltipText2>
    Click to compare build machines 
    <br/>
    
    
    </MetaTooltipText2>
    </MetaTooltip2>
    </PricingNode15>
    <PricingNode15>
    
    <PricingValue1>
    <PricingPlanP>
    Elite
    </PricingPlanP>
    </PricingValue1>
    <MetaTooltip2 className='tooltips'>
    <MetaTooltipText2>
    Click to compare build machines 
    <br/>
    
    
    </MetaTooltipText2>
    </MetaTooltip2>
    </PricingNode15>
    <PricingNode16>
    
    <PricingValue1>
    <PricingPlanP>
    Standard, Elite
    </PricingPlanP>
    </PricingValue1>
    <MetaTooltip2 className='tooltips'>
    <MetaTooltipText2>
    Click to compare build machines 
    <br/>
    
    
    </MetaTooltipText2>
    </MetaTooltip2>
    </PricingNode16>
    <PricingMeta>
    <PricingMetaHeader>
    Users
    </PricingMetaHeader>
    </PricingMeta>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode12>
    
    </PricingNode12>
    
    <PricingMeta1>
    <PricingMetaHeader1>
    Team Members
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    The number of collaborators allowed for an app. 
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode13>
    <PricingValue>
    2
    </PricingValue>
    </PricingNode13>
    <PricingNode13>
    <PricingValue>
    Unlimited
    </PricingValue>
    </PricingNode13>
    <PricingNode13>
    <PricingValue>
    Unlimited
    </PricingValue>
    </PricingNode13>
    <PricingNode14>
    <PricingValue>
    Unlimited
    </PricingValue>
    </PricingNode14>
    
    <PricingMeta1>
    <PricingMetaHeader1>
    Group management
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    Roles can be assigned to your team members, e.g. admin, developer, tester.
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode14>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode14>
    <PricingMeta1>
    <PricingMetaHeader1>
    Single Sign-on (SAML SSO)
    
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    Single Sign-on can be used for logging in more safely to Bitrise.
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode14>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode14>
    <PricingMeta>
    <PricingMetaHeader>
    Support
    </PricingMetaHeader>
    </PricingMeta>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode11>
    
    </PricingNode11>
    <PricingNode12>
    
    </PricingNode12>
    <PricingMeta1>
    <PricingMetaHeader1>
    Community Support
    
    
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    Ask questions and answer fellow developers' on our Discuss page or Slack channel.
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode14>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode14>
    <PricingMeta1>
    <PricingMetaHeader1>
    Email Support
    
    
    
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    Ticket based email support is available for 2 weeks after registration and for our paid plans.
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode14>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode14>
    <PricingMeta1>
    <PricingMetaHeader1>
    Premium Support
    
    
    
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    This option includes ticket response SLA, optional dedicated onboarding, Slack support, and Bitrise consulting sessions.
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode13>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon>
    </PricingNode13>
    <PricingNode14>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode14>
    <PricingMeta1>
    <PricingMetaHeader1>
    Customer success team
    
    
    
    
    </PricingMetaHeader1>
    <MetaInfo1>
    <MetaIcon1>
    
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 13.3333C10.9334 13.3333 13.3334 10.9333 13.3334 7.99998C13.3334 5.06665 10.9334 2.66665 8.00004 2.66665C5.06671 2.66665 2.66671 5.06665 2.66671 7.99998C2.66671 10.9333 5.06671 13.3333 8.00004 13.3333ZM8.00004 14.6666C4.33337 14.6666 1.33337 11.6666 1.33337 7.99998C1.33337 4.33331 4.33337 1.33331 8.00004 1.33331C11.6667 1.33331 14.6667 4.33331 14.6667 7.99998C14.6667 11.6666 11.6667 14.6666 8.00004 14.6666ZM7.20004 8.99998C7.20004 7.66665 8.73337 7.66665 8.73337 6.73331C8.73337 6.33331 8.40004 6.06665 7.93337 6.06665C7.06671 5.99998 6.80004 6.73331 6.80004 6.73331L5.66671 6.19998C5.66671 6.19998 6.13337 4.66665 7.93337 4.66665C9.26671 4.66665 10.1334 5.53331 10.1334 6.53331C10.1334 8.26665 8.46671 8.26665 8.46671 9.39998H7.26671C7.26671 9.39998 7.20004 9.19998 7.20004 8.99998ZM7.06671 10.6666C7.06671 10.2666 7.40004 9.99998 7.80004 9.99998C8.20004 9.99998 8.53337 10.3333 8.53337 10.6666C8.53337 11.0666 8.20004 11.3333 7.80004 11.3333C7.40004 11.3333 7.06671 11 7.06671 10.6666Z" fill="#351D48"></path>
    </svg>
    
    </MetaIcon1>
    <MetaTooltip1 className='tooltips'>
    <MetaTooltipText1>
    This option includes named account management and high-level technical specialists to ensure you get the most out of Bitrise.
    <br/>
    </MetaTooltipText1>
    </MetaTooltip1>
    </MetaInfo1>
    </PricingMeta1>
    
    <PricingNode17>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon>
    </PricingNode17>
    <PricingNode17>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon>
    </PricingNode17>
    <PricingNode17>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon>
    </PricingNode17>
    <PricingNode18>
    <PricingValueIcon>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon>
    </PricingNode18>
    
    </PricingLayout>
    </PricingContainer>
    
    }
    
    {
      third &&
      <PricingTabContainer>
    <PricingTabGrid>
    
    <PricingNode20>
    
    <PrivateHeading>Private Cloud Plans</PrivateHeading>
    
    <PricingTabInfo>
    Enjoy all the benefits of a fully managed cloud solution, combined with the high availability, control, and security usually reserved for on-prem solutions. Learn more
    </PricingTabInfo>
    
    
    
    </PricingNode20>
    
    <PricingNode21>
    <PricingTabHeader>
    FOR TEAMS AND ORGANIZATIONS
    </PricingTabHeader>
    </PricingNode21>
    <PricingNode22>
    <PricingTabHeader1>
    Dedicated Build Platform
    </PricingTabHeader1>
    </PricingNode22>
    <PricingNode23>
    <PricingTabHeader1>
    Private Build Platform
    </PricingTabHeader1>
    </PricingNode23>
    
    <PricingNode24>
    
    <PricingTabl>
    Custom
    </PricingTabl>
    <PricingTabs>
    pricing
    </PricingTabs>
    
    
    
    </PricingNode24>
    <PricingNode25>
    
    <PricingTabl>
    Custom
    </PricingTabl>
    <PricingTabs>
    pricing
    </PricingTabs>
    
    
    
    </PricingNode25>
    <PricingNode24>
    
    <PricingTabAnchor>
    <a href="https://www.bitrise.io/contact" class="pricing-plan-button pricing-plan-button-aqua w-button" style={{color:"white"}}>Talk to us</a>
    </PricingTabAnchor>
    
    
    
    </PricingNode24>
    <PricingNode25>
    
    <PricingTabAnchor>
    <a href="https://www.bitrise.io/contact" class="pricing-plan-button pricing-plan-button-aqua w-button"style={{color:"white"}}>Talk to us</a>
    </PricingTabAnchor>
    
    </PricingNode25>
    
    <PricingNode24>
    
    <PricingPlanInfo>
    Ideal for bigger, more complex teams that value speed, but optimize for efficiency
    </PricingPlanInfo>
    
    </PricingNode24>
    <PricingNode25>
    Built for organizations where security or speed is the number one priority
    <PricingPlanInfo>
    
    </PricingPlanInfo>
    </PricingNode25>
    
    
    <PricingPlanMeta1>
    <PricingPlanMetaLabel>
    
    
    Dedicated hosts
    </PricingPlanMetaLabel>
    </PricingPlanMeta1>
    
    <PricingNode24>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon1>
    </PricingNode24>
    <PricingNode25>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon1>
    </PricingNode25>
    <PricingPlanMeta1>
    <PricingPlanMetaLabel>
    
    
    Support level
    </PricingPlanMetaLabel>
    </PricingPlanMeta1>
    <PricingNode24>
    
    <PricingPlanInfo1>
    Premium
    </PricingPlanInfo1>
    
    </PricingNode24>
    <PricingNode25>
    
    <PricingPlanInfo1>
    Premium
    </PricingPlanInfo1>
    </PricingNode25>
    <PricingPlanMeta1>
    <PricingPlanMetaLabel>
    
    
    Mobile DevOps process support
    </PricingPlanMetaLabel>
    </PricingPlanMeta1>
    <PricingNode24>
    
    <PricingPlanInfo1>
    Included
    </PricingPlanInfo1>
    
    </PricingNode24>
    <PricingNode25>
    
    <PricingPlanInfo1>
    Included
    </PricingPlanInfo1>
    </PricingNode25>
    <PricingPlanMeta1>
    <PricingPlanMetaLabel>
    
    
    Infra management dashboards
    </PricingPlanMetaLabel>
    </PricingPlanMeta1>
    
    <PricingNode24>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon1>
    </PricingNode24>
    <PricingNode25>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon1>
    </PricingNode25>
    <PricingPlanMeta1>
    <PricingPlanMetaLabel>
    
    
    Dedicated vSphere server
    </PricingPlanMetaLabel>
    </PricingPlanMeta1>
    
    <PricingNode24>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon1>
    </PricingNode24>
    <PricingNode25>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon1>
    </PricingNode25>
    <PricingPlanMeta1>
    <PricingPlanMetaLabel>
    
    
    Dedicated storage
    </PricingPlanMetaLabel>
    </PricingPlanMeta1>
    
    <PricingNode24>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon1>
    </PricingNode24>
    <PricingNode25>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon1>
    </PricingNode25>
    <PricingPlanMeta1>
    <PricingPlanMetaLabel>
    
    
    Dedicated firewall/networking
    </PricingPlanMetaLabel>
    </PricingPlanMeta1>
    
    <PricingNode24>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.01 10.6077L16.6177 6L18.02 7.40233L13.4123 12.01L18.02 16.6177L16.6177 18.02L12.01 13.4123L7.40233 18.02L6 16.6177L10.6077 12.01L6 7.40233L7.40233 6L12.01 10.6077Z" fill="#ACACAC"></path>
    </svg>
    
    </PricingValueIcon1>
    </PricingNode24>
    <PricingNode25>
    <PricingValueIcon1>
    
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2128 13.9149L17.234 7L18.7234 8.48936L10.2128 17L5 11.6809L6.48936 10.1915L10.2128 13.9149Z" fill="#0FC389"></path>
    </svg>
    </PricingValueIcon1>
    </PricingNode25>
    </PricingTabGrid>
    </PricingTabContainer>
    
    }

    
    </PricingWrapper10>
    </PricingSection10>
    
    
    
    
    
    
    
    
    
    
    <Wrapper2>
    <Heading3>Used by the world's most productive mobile teams</Heading3>
    <ImageSection>
    <ImgPara> <img src="mozilla.svg" alt="email-icon"/></ImgPara>
    <ImgPara>  <img src="transferwise.svg" alt="email-icon"/></ImgPara>
    <ImgPara> <img src="dena.svg" alt="email-icon"/></ImgPara>
    <ImgPara> <img src="crareem.svg" alt="email-icon"/></ImgPara>
    <ImgPara>  <img src="pluselive.png" alt="email-icon"/></ImgPara>
    </ImageSection>
    </Wrapper2>
    <Wrapper3>
    <TopNotch>
    Top-notch mobile DevOps features in all plans
    </TopNotch>
    </Wrapper3>
    <MainWrapper>
    <Wrapper4>
    <PricingFeature>
    
    <ImgLogo><img src="500.png" alt="email-icon" style={{ width: "64px", height: "64px" }}/></ImgLogo>
    <Heading7> Scalable, managed infra</Heading7>
    <ParaPricing>Bitrise creates a new Virtual Machine for each of your builds. The actual hardware is maintained by us which means no more Mac Minis under the table.</ParaPricing> </PricingFeature>
    
    <PricingFeature>
    <ImgLogo><img src="501.png" alt="email-icon" style={{ width: "64px", height: "64px" }}/></ImgLogo>
    <Heading7> API and extensibility</Heading7>
    <ParaPricing> Configure your Bitrise workflows through the YML, build or customize steps and store them privately, insert custom code or use the extensive Bitrise API to build deep, custom integrations.</ParaPricing> </PricingFeature>
    <PricingFeature>
    <ImgLogo><img src="502.png" alt="email-icon" style={{ width: "64px", height: "64px" }}/></ImgLogo>
    <Heading7> Your favorite services, integrated</Heading7>
    <ParaPricing> Use your favorite tools for building, testing and deployment, out of the box. If you can’t find it, write it, it’s open-source.</ParaPricing> </PricingFeature>
    <PricingFeature>
    
    <ImgLogo><img src="503.png" alt="email-icon" style={{ width: "64px", height: "64px" }}/></ImgLogo>
    <Heading7> Low setup and maintenance</Heading7>
    <ParaPricing>Use our default setups and reduce countless hours of setup and maintenance to a click and a handful of variables. We keep track of updates too so your build won’t fail.</ParaPricing> </PricingFeature>
    
    <PricingFeature>
    <ImgLogo><img src="504.png" alt="email-icon" style={{ width: "64px", height: "64px" }}/></ImgLogo>
    <Heading7> Free Test Reports</Heading7>
    <ParaPricing>  Run your UI tests and Unit tests using our testing Steps and view the results of your tests in one place, in an easy to read format.</ParaPricing> </PricingFeature>
    <PricingFeature>
    <ImgLogo><img src="505.png" alt="email-icon" style={{ width: "64px", height: "64px" }}/></ImgLogo>
    <Heading7>Latest dev stacks</Heading7>
    <ParaPricing> Use the latest developer tools for your app, including betas. The newest Xcode version is usually available within 24-48 hours.</ParaPricing> </PricingFeature>
    </Wrapper4>
    </MainWrapper>
    <Heading1>Frequently Asked Questions</Heading1>
    <HR />
    <PricingWrapper>
    
    
    
    
    
    
    <Collapse heading="When should we choose the Velocity plan?">
    <>
    <PanelPara> The Velocity plan is for teams that need additional flexibility, capacity or performance. Build when you want, how you want: there are virtually no restrictions on the minimum or maximum parallel builds and the virtual machines available are faster than those offered on our other plans. Plans are usage based, providing you with credits you can use to select the right machine type per workflow.</PanelPara>
    </>
    </Collapse>
    <HR2/>
    
    
    <Collapse heading="How do build machines compare?">
    <>
    <PanelPara>
    <PricingPara1>
    On the Velocity plan, you can choose from 3 machine types (Standard, Elite, and Elite XL) for each of your builds.
    </PricingPara1>
    <PricingPara2>Linux (Ubuntu) Virtual Machines</PricingPara2>
    <PricingRow>
    <PricingCell>
    <PricingType>Standard</PricingType>
    <PricingPlan>Available in Developer, Org Standard, Velocity plan</PricingPlan>
    </PricingCell>
    <PricingCell>
    <PricingSpec>2vCPU @ 2.6 GHz, 7.5 GB RAM</PricingSpec>
    
    </PricingCell>
    </PricingRow>
    <PricingRow>
    <PricingCell>
    <PricingType>Elite</PricingType>
    <PricingPlan>Available in Org Elite, Velocity plan</PricingPlan>
    </PricingCell>
    <PricingCell>
    <PricingSpec>4vCPU @ 2.6 GHz, 15 GB RAM</PricingSpec>
    
    </PricingCell>
    </PricingRow>
    <PricingRow>
    <PricingCell>
    <PricingType>Elite XL</PricingType>
    <PricingPlan>Available in Org Elite, Velocity plan</PricingPlan>
    </PricingCell>
    <PricingCell>
    <PricingSpec>8vCPU @ 2.6 GHz, 30 GB RAM</PricingSpec>
    
    </PricingCell>
    </PricingRow>
    <PricingPara2>macOS Virtual Machines</PricingPara2>
    <PricingRow>
    <PricingCell>
    <PricingType>Standard</PricingType>
    <PricingPlan>Available in Developer, Org Standard, Velocity plan</PricingPlan>
    </PricingCell>
    <PricingCell>
    <PricingSpec>2vCPU @ 2.7 GHz, 4 GB RAM</PricingSpec>
    
    </PricingCell>
    </PricingRow>
    <PricingRow>
    <PricingCell>
    <PricingType>Elite</PricingType>
    <PricingPlan>Available in Org Elite, Velocity plan</PricingPlan>
    </PricingCell>
    <PricingCell>
    <PricingSpec>4vCPU @ 2.6 GHz, 15 GB RAM</PricingSpec>
    
    </PricingCell>
    </PricingRow>
    <PricingRow>
    <PricingCell>
    <PricingType>Elite XL</PricingType>
    <PricingPlan>Available in Velocity plan</PricingPlan>
    </PricingCell>
    <PricingCell>
    <PricingSpec>8vCPU @ 2.6 GHz, 30 GB RAM</PricingSpec>
    
    </PricingCell>
    </PricingRow>
    </PanelPara>
    </>
    </Collapse>
    <HR2/>
    <Collapse heading="What are concurrencies?">
    <>
    <PanelPara><PricingFaq>One concurrency = one build at a timeOne concurrency = one build at a time <br/><br/>
    This means that if you trigger 5 builds simultaneously, while you only have a single concurrency available, you’ll have to wait for each one to finish before the next one can start. However, if you have 5 concurrencies, the builds will run in parallel. On Org plans, concurrencies are limited to 10. If you need more, talk to us about the Velocity plan.
    </PricingFaq></PanelPara>
    </>
    </Collapse>
    <HR2/>
    <Collapse heading="What is a Build timeout?">
    <>
    <PanelPara>To keep builds from unintentionally running indefinitely, and locking up your concurrencies or using up your build minutes, build fails after a certain amount of time. On Velocity plan this limit can be up to 4 hours, which should allow for even the biggest, most complex builds to finish.</PanelPara>
    </>
    </Collapse>
    <HR2/>
    <Collapse heading="What does the Hobby plan include?">
    <>
    <PanelPara>The Hobby plan is free. Forever. No strings attached, no credit card needed. We give you 1 concurrency with 30 mins/build timeout and you can run 200 builds a month. You can also invite someone to work on your app. Try Bitrise, it’s on us.</PanelPara>
    </>
    </Collapse>
    <HR2/>
    <Collapse heading="What types of support do you provide?">
    <>
    <PanelPara><PricingFaq>All Bitrise accounts come with community support: ask questions and answer fellow developers' on our Discuss page or Slack channel. In addition to this, ticket-based email support is available for 2 weeks during Trial and later for our paid plans only.<br/>
    <br/>Customers on the Velocity plan will gain access to a customer success manager and a technical account manager ensuring that they get the most out of Bitrise.<br/><br/>
    Customers on the Velocity plan will gain access to a customer success manager and a technical account manager ensuring that they get the most out of Bitrise.
    </PricingFaq></PanelPara>
    </>
    </Collapse>
    <HR2/>
    <Collapse heading="I have a Dev account, but I need an Org. What happens now?">
    <>
    <PanelPara>Subscribe to one of our Org plans and cancel the Solo Developer plan if you don’t need it any longer.</PanelPara>
    </>
    </Collapse>
    <HR2/>
    <Collapse heading="Do you have discounts for contributors, students, or Open Source projects?">
    <>
    <PanelPara><PricingFaq>Yup.<br/><br/>
    • As developers, we appreciate your effort towards the developer community, so we provide extra benefits for each of the public apps you create. Get a free concurrency and 45 mins extra build time for each of your public projects automatically.
    <br/><br/>
    • If you are happy to contribute to Bitrise, we have an offer for you. Get the Developer plan for $20/month if you contribute to any of the open-source parts of Bitrise.<br/><br/>
    • If you are eager to learn, we are happy to contribute. Get the Developer plan for $20/month if you are a student with an active semester.
    </PricingFaq> </PanelPara>
    </>
    </Collapse>
    <HR2/>
    </PricingWrapper>
    <PricingSection>
    <PricingHeadline>
    Sign up now — choose a plan later
    </PricingHeadline>
    <PricingCta>
    Take a look around and find your perfect fit!
    </PricingCta>
    <Pricingbtn>
    <a href="https://app.bitrise.io/users/sign_up" class="pricing-cta-large w-button" style={{color:"white",textDecoration:"none"}}>Try for free</a>
    </Pricingbtn>
    <PricingCta1>
    Do you still have questions about our plans?
    <br/>
    Get in touch
    </PricingCta1>
    </PricingSection>
    </Layout>
    )
  }
  
  export default Pricing;
  