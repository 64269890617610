import { useEffect } from "react";
import { Icon } from "@iconify/react";
import shoppingCartFill from "@iconify/icons-eva/shopping-cart-fill";
// material
import { styled } from "@mui/material/styles";
import { Badge } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  cartClose,
  cartOpen,
  handleSetCount,
} from "../../../../redux/actions/cartActions";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: "flex",
  cursor: "pointer",
  position: "fixed",
  alignItems: "center",
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadiusMd,
  borderBottomLeftRadius: theme.shape.borderRadiusMd,
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function CartWidget() {
  const dispatch = useDispatch();
  const { cartCount, isCartOpen, checkout } = useSelector((state) => ({
    cartCount: state.cart.cartCount,
    isCartOpen: state.cart.isCartOpen,
    checkout: state.checkout.checkout,
  }));
  const onClickCartIcon = () => {
    if (isCartOpen) {
      dispatch(cartClose());
    } else {
      dispatch(cartOpen());
    }
  };

  useEffect(() => {
    function getCount() {
      let lineItems =
        checkout.lineItems && checkout.lineItems.length > 0
          ? checkout.lineItems
          : [];
      let count = 0;
      lineItems.forEach((item) => {
        count += item.quantity;
        return count;
      });
      dispatch(handleSetCount(count));
    }

    getCount();
  }, [checkout]);

  return (
    <RootStyle onClick={onClickCartIcon}>
      <Badge showZero badgeContent={cartCount} color="error" max={99}>
        <Icon icon={shoppingCartFill} width={24} height={24} />
      </Badge>
    </RootStyle>
  );
}
