import React from 'react';
import styled from "styled-components";
import Layout from '../Layout';

const Container = styled.section`
position: relative;
top: 0;
bottom: 0;
display: flex;
margin-top: 0;
padding-top: 144px;
padding-bottom: 80px;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
flex-direction: column;
-webkit-box-pack: start;
justify-content: flex-start;
-webkit-box-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
max-width: 941px;
&:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}
`;
const Wrapper = styled.section`
display: block;
width: 75%;
max-width: 960px;
margin-top: 0;
font-family: 'TT Norms Pro Bold', sans-serif;
text-align: left;
`;
const Heading1 = styled.h1`
font-size: 67px;
margin: 0;
font-family: 'TT Norms Pro Bold', sans-serif;
color: #333333;
font-weight: 500;
`;
const Heading2 = styled.h1`
margin-top: 80px;
margin-bottom: 24px;
font-size: 47px;
line-height: 1.4em;
font-weight: 700;
text-align: left;
text-transform: none;
opacity: 0.6;
`;
const Tag = styled.p`
color: #616161;
line-height: 1.5em;
font-size: 16px;
font-family: 'TT Norms Pro ExtraBold', sans-serif;
`;
const Anchor = styled.a`
font-size: 16px;
color: black;
text-decoration: none;
`;
const BodyHeading = styled.p`
margin-top: 80px;
margin-bottom: 24px;
opacity: 0.6;
font-size: 47px;
line-height: 1.4em;
font-weight: 700;
text-align: left;
text-transform: none;
`;
const BodyText = styled.p`
font-size: 16px;  
margin-bottom: 24px;
line-height: 1.5em;
font-family: 'TT Norms Pro Medium', sans-serif;
color: #616161;
`;
const Heading3 = styled.h3`
font-size: 25px;
line-height: 27px;
font-weight: 700;
text-align: left;
text-transform: none;
margin-bottom: 0px;
opacity: 0.6;
`;
const ListHeading = styled.p`
font-size: 16px;  
color: #616161;
line-height: 1.5em;
font-weight: 700;
`;
const OrderedList = styled.ol`

`;
const ListItem = styled.li`
font-size: 16px; 
line-height: 1.5em;
font-family: 'TT Norms Pro Medium', sans-serif;
color: #616161;
`;
const UnorderedList = styled.ul`
font-size: 15px;
`;
const StrongText = styled.strong`
font-weight: 900;
font-family: 'TT Norms Pro ExtraBold', sans-serif;
`;
const Heading5 = styled.h5`
font-size: 20px;
line-height: 22px;
margin: 0;
font-weight: 300;
`;

const CookiePolicy = () => {
    return (
        <Layout>
        <Container>
        <Wrapper>
        
        <Heading1>Cookie policy</Heading1>
        <BodyText>We use cookies and similar tools on our website, <a href="https://www.bitrise.io/">https://www.bitrise.io/</a>  <StrongText>(Website)</StrongText> to improve its performance and enhance your user experience. This policy explains how we do that.</BodyText>
        <Heading2><StrongText>What are cookies?</StrongText></Heading2>
        <BodyText>Cookies are small text files which a website may put on your computer or mobile device when you first visit a site or page. The cookie will help the website, or another website, to recognise your device the next time you visit. We use the term “cookies” in this policy to refer to all files that collect information in this way.</BodyText>
        <BodyText>There are many functions cookies serve. For example, they can help us to remember your username and preferences, analyse how well the Website is performing, or even allow us to recommend content we believe will be most relevant to you.</BodyText>
        <BodyText>Certain cookies contain personal information – for example, if you click to “remember me” when logging in, a cookie will store your username. Most cookies will not collect information that identifies you, but will instead collect more general information such as how users arrive at and use the Website.</BodyText>
       <Heading2><StrongText>What sort of cookies does the Website use?</StrongText></Heading2>
        <BodyText>Generally, our cookies perform up to four different functions:</BodyText>
        <OrderedList role="list">
       <ListItem><em>Essential cookies<br /></em>Some cookies are essential for the operation of the Website. If you opt to disable these cookies, you will not be able to access or use all of the features that the Website incorporates.</ListItem>
      <ListItem><em>Performance Cookies<br /></em>We utilise other cookies to analyse how our visitors use the Website and to monitor performance. This allows us to provide a high quality experience by customising our offering and quickly identifying and fixing any issues that arise. For example, we might use performance cookies to keep track of which pages are most popular, which method of linking between pages is most effective, and to determine why some pages are receiving error messages.</ListItem>
      <ListItem><em>Functionality Cookies<br /></em>We use functionality cookies to allow us to remember your preferences. For example, cookies save you the trouble of typing in your username every time you use the Website, and recall your customisation preferences.</ListItem>
     <ListItem><em>Targeting Cookies<br /></em>These cookies record your visit to our Website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.]</ListItem>
      </OrderedList>
       <Heading2><StrongText>Does anyone else use cookies on the Website?</StrongText></Heading2>
        <BodyText>We do use or allow third parties to serve cookies that fall into the three categories above. For example, like many companies, we use Google Analytics to help us monitor our traffic. We may also use third party cookies to help us with market research, revenue tracking, improving site functionality and monitoring compliance with our terms and conditions and copyright policy</BodyText>
        <Heading2><StrongText>Can you block cookies?</StrongText></Heading2>
        <BodyText>As we have explained above, cookies help you to get the most out of the Website. However, if you do wish to disable cookies then you can do so by amending the settings within your browser or mobile device. Please remember that if you do choose to disable cookies, you may find that certain sections of the Website do not work properly.</BodyText>
        <Heading2><StrongText>More Information</StrongText></Heading2>
        <BodyText>More detail on how businesses use cookies is available at <a href="https://www.whatarecookies.com/">www.whatarecookies.com</a>. If you have any queries regarding this Cookie Policy please contact our Privacy Officer by e-mail at letsconnect@bitrise.io</BodyText>
        <Heading2><StrongText>Triple Chocolate Cookies</StrongText></Heading2>
        <Heading3>
<StrongText>INGREDIENTS</StrongText></Heading3>

        <BodyText>| | | ---: | :--- 2-1/2 sticks | Butter, Softened 2 cups | Sugar 2 whole | Large Eggs 3 teaspoons | Vanilla Extract 2-1/4 cups | All-purpose Flour 3/4 cups | Unsweetened Cocoa Powder 1-1/2 teaspoon | Baking Soda 1 teaspoon | Salt 1-1/4 cup | Milk Chocolate Chips 1-1/4 cup | Good Semi Sweet Chocolate Chips</BodyText>
        <Heading3>
<StrongText>INSTRUCTIONS</StrongText></Heading3>

         <OrderedList role="list">
       <ListItem>
Preheat oven to 350°F (~176.67 ℃).</ListItem>

       <ListItem>
Using a mixer, cream butter and sugar until fluffy, scraping the sides once.</ListItem>

       <ListItem>
Add eggs [🥚] one at a time, mixing after each addition. Mix in vanilla.</ListItem>

       <ListItem>
Sift together dry ingredients, then add in batches to the mixer, mixing until just combined after each addition.</ListItem>

       <ListItem>
Gently blend in chocolate chips, reserving a few for the tops of the cookies</ListItem>

       <ListItem>
Using a cookie scoop, add generous 1 tablespoon (0.062 cups) portions to baking sheet. Dot the top of each cookie with 2 to 3 chocolate chips if you’d like them to really show up on top. Bake for 9 to 11 minutes (540000 - 660000 milliseconds) or until cookies are barely done but still soft and chewy. (Don't overbake!)</ListItem>

       <ListItem>
Let cool on a baking rack. 🍪 Happy building!</ListItem>

         </OrderedList>

        
        </Wrapper>
        </Container>
        </Layout>
        )
    }
    
    export default CookiePolicy;
    