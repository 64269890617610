import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  gap: 20px;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const Image = styled.img`
  border-radius: 50%;
  width: 240px;
  margin-bottom: 25px;
  @media (max-width: 780px) {
    width: 60%;
  }
`;
const Heading3 = styled.h3`
  font-size: 28px;
  line-height: 30px;
  margin: 0;
  opacity: 0.6;
  font-weight: 300;
`;
const BodyHeading = styled.p`
  font-size: 14px;
  line-height: 20px;
  opacity: 0.6;
`;
const BodyText = styled.p`
  font-size: 17px;
  line-height: 24px;
  opacity: 0.6;
`;
const Icon = styled.button`
  border: 1px solid #0A66C2;
  outline: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: white;
  transition: 0.5s;
  color: #0A66C2;
  &:hover {
    background: #0A66C2;
    color: white;
    cursor: pointer;
  }
`;
const IconText = styled.text`
  font-weight: 700;
  font-size: 24px;
`;
const HR = styled.hr`
  opacity: 0.6;
  border: 0;
  border-bottom: 1px solid gray;
  margin-bottom: 3%;
  margin-top: 3%;
  @media (max-width: 780px) {
    margin-bottom: 10%;
    margin-top: 10%;
  }
`;

const Profile = (props) => {
  const { url, name, rank, para1, para2 } = props;
  return (
    <div>
      <HR />
      <Wrapper>
        <ImageSection>
          <Image src={url} />
          <Heading3>{ name }</Heading3>
          <BodyHeading><em>{ rank }</em></BodyHeading>
          <Icon><IconText>in</IconText></Icon>
        </ImageSection>
        <InfoSection>
          <BodyText>{ para1 }</BodyText>
          <BodyText>{ para2 }</BodyText>
        </InfoSection>
      </Wrapper>
    </div>
  );
}

export default Profile;