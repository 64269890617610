import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Images from "../../Constants/Images";
import Offices from "./Offices";
import Layout from "../Layout";

function Jobs(props) {
  const imgData = [
    {
      _id: 1,
      image: Images.Partech,
    },
    {
      _id: 2,
      image: Images.Zobito,
    },
    {
      _id: 3,
      image: Images.OpenOcean,
    },
    {
      _id: 4,
      image: Images.YCobinator,
    },
    {
      _id: 5,
      image: Images.FiedlerCapital,
    },
  ];
  return (
    <Layout>
      <main id="page_content">
        <section className="sec_1">
          <Container>
            <h1>
              We truly believe that app development should be all about the
              process of creating something extraordinary.
            </h1>
            <Row>
              <Col lg={6} md={6} xs={12}>
                <p>
                  We experienced firsthand the struggle and annoying
                  distractions app developers have to face almost every day. We
                  were those developers. And we will not forget. We are working
                  hard to make developers’ life better.
                </p>
              </Col>
              <Col lg={6} md={6} xs={12}>
                <img src={Images.Robot} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </section>
        <Offices />
        <section className="sec_3">
          <Container>
            <h3>Our Investors</h3>
            <div className="div-block-25">
              {imgData.map((todo, index) => (
                <img src={todo.image} alt="" key={`id-${index}-${todo._id}`} />
              ))}
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default Jobs;
