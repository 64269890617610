import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout';
import ReactPlayer from "react-player"


const Wrapper = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 150px;
`;

const ImgLogo = styled.div`
margin-bottom: 16px;
display: block;
border-color: transparent;
border: 0;
max-width: 100%;
text-align:left;
`;

const Window = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5% 5% 5% 5%;
  @media (max-width: 780px) {
    background-size: contain;
    padding: 5% 20% 5% 5%;
  }
`;

const UnderLine = styled.div`
  color:#C4C4C4;
  width: 1170px;
  ${'' /* height: 0px; */}
  border: 1px solid #C4C4C4;
`;

const BannerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom:150px;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 50% 5% 5%;
  ${'' /* height: 750px; */}
  width: 100vw;
  max-width: 1600px;
  
`;

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 50% 5% 5%;

  ${'' /* height: 750x; */}
  width: 100vw;
  max-width: 1600px;
  gap: 20px;
  @media (max-width: 780px) {
    flex-wrap: wrap;
    background-size: contain;
    padding: 5% 20% 5% 5%;
  }
  @media (max-width: 780px) {
    background-size: contain;
    padding: 5% 20% 5% 5%;
  }
`;
const Heading = styled.h1`
  font-weight: 600;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 50px;
  color:"black"
`;
const PriceText = styled.p`
color:#757095;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 70px;
`;
const BodyText = styled.p`
color:#656565;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 30px;
`;


const Bullet = styled.ul`
margin:0;
padding:0;
list-style-position:inside;

`;

const BulletList = styled.li`
color:#656565;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 30px;
margin-bottom:8px;
`;


const Button = styled.button`
  background: ${props => props.blackbg ? 'black' : 'transparent'};
  border-radius: 5px;
  white-space: nowrap;
  font-size: 20px;
  transition: 0.5s;
  padding: 10px;
  outline: 0;
  width: 250px;
  height: 60px;
  color: white;
  &:hover {
    color: ${props => props.blackbg ? 'black' : 'darkblue'};
    background: white;
  }
  &:focus {
    outline: 0;
  }
`;

const HomePageRecent = () => {
  return (
    <Layout variant="Standard">
      <Wrapper>
        <Banner>
        <ImgLogo><img src="Techlabz-Keybox-Aerial-View.png" alt="email-icon" style={{ marginTop:100, width: "603px", height: "500px" }}/></ImgLogo>
        <Window>
        <Heading>TECHLABZ KEYBOX</Heading>
          <PriceText>$199.99</PriceText>
          <BodyText style={{ marginBottom:50 }}>The Techlabz Keybox allows you to use your favorite laptop keyboard over USB or Bluetooth. It supports most laptop keyboard and legacy laptop keyboards in the market. 
            Dell, Asus, Apple, HP, Chromebook,etc. You can place your keyboard into our case (or not) and use it as standalone USB/Wireless Keyboard. Or you can place with Adhesive on top of your laptop palmrest and use in lieu of keyboard there. 
          </BodyText>
          <Bullet>
              <BulletList>Techlabz Keyboard Features</BulletList>
              <BulletList>USB Type-C</BulletList>
              <BulletList>Bluetooth 4.1 LE + EDR</BulletList>
              <BulletList>300mAh/1.1Wh Battery </BulletList>
              <BulletList style={{ marginBottom:30 }}>Ambient Light Detector</BulletList>
          </Bullet>
          <Button blackbg onClick={(event) => (window.location.href = "https://shop.techlabz.io/52568424633/checkouts/bdca581094bed9d4bb05fb8c4e21ee0c")}>Pre-Order </Button>
          <BodyText style={{ marginTop:15 }}> *Ships on or before October 1
          </BodyText>
        </Window>
        </Banner>
        <Banner>
        <ReactPlayer url="https://www.youtube.com/watch?v=ybU_kFlvTZk&t=3s"/>
        </Banner>
        <BannerColumn>
        <BodyText style={{ marginTop:"150px" }}>Connection Diagram </BodyText>
        <ImgLogo><img src="Connection-Diagram.PNG" alt="email-icon" style={{ marginBottom:"150px", width: "2077px", height: "500px" }}/></ImgLogo>
        <BodyText style={{ marginTop:15 }}> DESCRIPTION </BodyText>
        <UnderLine></UnderLine>
        <BodyText style={{ marginBottom:10 }}>How to Use? </BodyText>
        <BodyText style={{ marginBottom:50 }}>You can either purchase a replacement laptop keyboard of your favorite laptop, or if you're a DIYer can remove from your laptop. <br/>
        Once you have the keyboard then you can interface with the DIA (Device Interface Adapter), the DIA connects to the Techlabz Keybox with a ffc cable. <br/>
        Next you download the appropriate software and configure keys, once done with setup you will be able to use the Techlabz Keybox.  </BodyText>
        <BodyText>In The Box </BodyText>
        <Bullet>
              <BulletList>Techlabz Keybox</BulletList>
              <BulletList>USB Cable</BulletList>
              <BulletList>Device Interface Adapter (DIA) (Interfaces with keyboard)</BulletList>
              <BulletList>FFC Cable (Connects to Keybox from the DIA) </BulletList>
              <BulletList>Keyboard Case</BulletList>
              <BulletList>Adhesive Strips</BulletList>
              <BulletList style={{ marginBottom:30 }}>Supporting Documents</BulletList>
          </Bullet>
        </BannerColumn>
      </Wrapper>
    </Layout>
  );
}

export default HomePageRecent;