import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Card, Link, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
// utils
import { fCurrency } from "../../utils/formatNumber";
//
import Label from "../Label";
import ColorPreview from "../ColorPreview";

import { Icon } from "@iconify/react";
import heartFill from "@iconify/icons-eva/heart-fill";
import heartOutline from "@iconify/icons-eva/heart-outline";
import shoppingCartOutline from "@iconify/icons-eva/shopping-cart-outline";
import shoppingCartFill from "@iconify/icons-eva/shopping-cart-fill";

import { useDispatch, useSelector } from "react-redux";
import { cartOpen, cartClose } from "../../../../redux/actions/cartActions";
import { addVariantToCart } from "../../../../redux/actions/checkoutActions";
/* import {
  removeLineItemInCart,
  updateQuantityInCart,
  addVariantToCart,
} from "../../../../redux/utills/cartHelpers"; */

// ----------------------------------------------------------------------

const ProductImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  orginalProduct: PropTypes.object,
};

const checkInCart = (lineItems, id) => {
  console.log("id", id);
  console.log("lineItems", lineItems);
  let result = false;
  if (lineItems) {
    lineItems.map((lineItem) => {
      if (lineItem.id === id) {
        result = true;
      }
    });
  }
  return result;
};

export default function ShopProductCard({ product, orginalProduct }) {
  const { name, cover, price, colors, status, priceSale } = product;

  const dispatch = useDispatch();
  const { isCartOpen, checkout, client } = useSelector((state) => ({
    isCartOpen: state.cart.isCartOpen,
    checkout: state.checkout.checkout,
    client: state.app.client,
  }));

  const [defaultColor, setDefaultColor] = useState(false);
  const [defaultCartColor, setDefaultCartColor] = useState(
    checkInCart(checkout.lineItems, orginalProduct.id)
  );

  const onClickWisheList = () => {
    setDefaultColor(!defaultColor);
  };

  const defaultSize =
    orginalProduct.variants && orginalProduct.variants[0].id.toString();
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState(1);
  const description =
    orginalProduct.description && orginalProduct.description.split(".");

  const onClickCart = () => {
    const lineItemsToAdd = [
      { variantId: defaultSize, quantity: parseInt(quantity, 10) },
    ];

    client.checkout
      .addLineItems(checkout.id, lineItemsToAdd)
      .then((response) => {
        console.log("response", response);
        //dispatch(cartOpen());
        dispatch(addVariantToCart(response));
        //setDefaultCartColor(!defaultCartColor);
      })
      .catch((error) => {
        console.log("add variant error: ", error);
      });
  };

  useEffect(() => {
    setDefaultCartColor(checkInCart(checkout.lineItems, orginalProduct.id));
  }, [checkout]);

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        {status && (
          <Label
            variant="filled"
            color={(status === "sale" && "error") || "info"}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: "absolute",
              textTransform: "uppercase",
            }}
          >
            {status}
          </Label>
        )}
        <Box
          sx={{
            zIndex: 9,
            top: 16,
            left: 16,
            position: "absolute",
            textTransform: "uppercase",
          }}
        >
          <Icon
            icon={defaultColor ? heartFill : heartOutline}
            width={24}
            height={24}
            style={{
              cursor: "pointer",
              color: defaultColor ? "#f44336" : "#333333",
            }}
            onClick={onClickWisheList}
          />
        </Box>
        <ProductImgStyle alt={name} src={cover} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to="#" color="inherit" underline="hover" component={RouterLink}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Link>

          <Icon
            icon={defaultCartColor ? shoppingCartFill : shoppingCartOutline}
            width={24}
            height={24}
            onClick={onClickCart}
            style={{
              cursor: "pointer",
              color: defaultCartColor ? "#f44336" : "#333333",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ColorPreview colors={colors} />
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: "text.disabled",
                textDecoration: "line-through",
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
