import React from "react";
import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { GrMail, GrLocation } from "react-icons/gr";
import { ImLocation } from "react-icons/im";



const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: #333333;
  bottom: 0;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4% 10% 3% 10%;

  background: #333333;
  @media (max-width: 780px) {
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding: 10%;
  }
`;
const LogoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 780px) {
    align-items: center;
  }
`;
const LogoDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 780px) {
    width: 100%;
    align-items: center;
  }
  &:focus {
    outline: 0;
  }
`;
const ButtonGroupTemp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 780px) {
    width: 100%;
    align-items: left;
  }
  &:focus {
    outline: 0;
  }
`;
const Button = styled.button`
  outline: 0;
  border: 0;
  color: white;
  transition: 0.5s;
  text-align: left;
  background: transparent;
  white-space: nowrap;
  font-family: "PT Sans", sans-serif;
  opacity: ${({ heading }) => (heading ? "1" : "0.4")};
  text-transform: ${({ heading }) => (heading ? "uppercase" : "default")};
  &:hover {
    opacity: 1;
    cursor: ${({ heading }) => (heading ? "default" : "pointer")};
  }
  &:focus {
    outline: 0;
  }
`;
const Label = styled.text`
  display: flex;
  align-items: center;
  opacity: 0.4;
  color: white;
  text-align: left;
  font-size: 14px;
  padding: 5px 0px 5px 0px;
  font-family: "PT Sans", sans-serif;
`;

const LabelForLocation = styled.text`
  display: flex;
  opacity: 0.4;
  color: white;
  text-align: left;
  font-size: 14px;
  padding: 5px 0px 5px 0px;
  font-family: "PT Sans", sans-serif;
`;

const CopyRight = styled.text`
  color: white;
  opacity: 0.4;
  font-size: 14px;
  @media (max-width: 780px) {
    text-align: center;
    marginleft: 30%;
  }
`;
const BottomSection = styled.div`
  color: white;
  text-align: center;
  padding: 0% 10% 0% 10%;
  padding-bottom: 5px;
  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const IconSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
const SocialIcon = styled.button`
  border: 0;
  outline: 0;
  color: #333333;
  width: 38px;
  height: 38px;
  background: #555555;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    transition: 0.5s;
    color: white;
    background: #ff0000;
    ${(props) => {
      switch (props.variant) {
        case "instagram":
          return "background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);";
        case "twitter":
          return "background: #1A91DA";
        case "facebook":
          return "background: #3B5998";
        case "linkedin":
          return "background: #0E76A8";
        case "youtube":
          return "background: #FF0000;";
        case "google":
          return "background: #FF0000;";
        case "pinterest":
          return "background: #b7081b;";
        default:
          return "border: 0";
      }
    }}
  }
`;

const Footer = ({ history }) => {
  return (
    <Container>
      <Wrapper>
        <LogoArea style={{}}>
          <Link
            to="/"
            style={{
              cursor: "pointer",
            }}
          >
            <LogoDiv>
              <img
                src="techlabz-logowhite.png"
                alt="techlabz-logo"
                width="50px"
                height="50px"
              />
              <img
                src="techlabz-textwhite.png"
                alt="techlabz-logo"
                width="180px"
                height="22px"
                style={{ marginTop: "6%", marginBottom: "15%" }}
              />
            </LogoDiv>
          </Link>
          <br />
          <a
            href="tel:3012025948"
            style={{
              cursor: "pointer",
            }}
          >
            <Label>
              <FaPhoneAlt style={{ marginRight: "10px" }} /> 301-202-5948
            </Label>
          </a>
          <a
            href="mailto:support@techlabz.io"
            style={{
              cursor: "pointer",
            }}
          >
            <Label>
              <GrMail style={{ marginRight: "10px" }} /> support@techlabz.io
            </Label>
          </a>
          <a
            href="https://goo.gl/maps/f6YBGNJv6DKmkDiu5"
            style={{
              cursor: "pointer",
            }}
          >
            <LabelForLocation>
              <ImLocation style={{ marginTop:"5px", marginRight: "10px" }} /> 
              <div>
              Techlabz LLC
              <br></br>8 The Green Site A
              <br></br>Dover, DE 19901
              </div>
            </LabelForLocation>
           
          </a>
        </LogoArea>
        {/* <ButtonGroup>
          <Button heading>Company</Button>
          <Button onClick={() => history.push("/about-us")}>About</Button>
          <Button onClick={() => history.push("/contact-us")}>Contact</Button>
          <Button onClick={() => history.push("/blog")}>Blogs</Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button heading>Legal</Button>
          <Button onClick={() => history.push("/privacy-policy")}>{" "}Privacy Policy{" "}</Button>
          <Button onClick={() => history.push("/cookie-policy")}>Terms of Service</Button>
          <Button onClick={() => history.push("/cookie-policy")}>Terms of Use</Button>
          <Button onClick={() => history.push("/privacy-policy")}>{" "}Refund Policy{" "}</Button>
        </ButtonGroup> */}
        <ButtonGroupTemp>
          <Button heading>Useful Links</Button>
          <Button onClick={() => history.push("/")}>{" "}Techlabz Keybox<span>&trade;</span>{" "}</Button>
          {/* <Button>Downloads</Button> */}
          {/* <Button>Forum</Button> */}
        </ButtonGroupTemp>
        <IconSection>
          <SocialIcon variant="youtube">
            <FaYoutube
              style={{ width: "18px", height: "18px" }}
              onClick={(event) =>
                (window.location.href = "https://www.youtube.com/channel/UCvMHUJmtlqBTxLIomhYEzSQ")
              }
            />
          </SocialIcon>
          <SocialIcon variant="twitter" onClick={(event) => (window.location.href = "https://twitter.com/tech_labz")}>
            <FaTwitter
              style={{ width: "18px", height: "18px" }}
            />
   
          </SocialIcon>
          {/* <SocialIcon variant="facebook">
            <FaFacebookF
              style={{ width: "18px", height: "18px" }}
              onClick={(event) =>
                (window.location.href = "https://www.facebook.com")
              }
            />
          </SocialIcon>
          <SocialIcon variant="linkedin">
            <FaLinkedinIn
              style={{ width: "18px", height: "18px" }}
              onClick={(event) =>
                (window.location.href = "https://www.linkedin.com")
              }
            />
          </SocialIcon>
          <SocialIcon variant="instagram">
            <FaInstagram
              style={{ width: "18px", height: "18px" }}
              onClick={(event) =>
                (window.location.href = "https://www.instagram.com")
              }
            />
          </SocialIcon>
          <SocialIcon variant="google">
            <FaGoogle
              style={{ width: "18px", height: "18px" }}
              onClick={(event) =>
                (window.location.href = "https://www.google.com")
              }
            />
          </SocialIcon>
          <SocialIcon variant="pinterest">
            <FaPinterest
              style={{ width: "18px", height: "18px" }}
              onClick={(event) =>
                (window.location.href = "https://www.pinterest.com")
              }
            />
          </SocialIcon> */}
        </IconSection>
      </Wrapper>
      <BottomSection>
        <CopyRight>
          © {new Date().getFullYear()} Techlabz LLC. All Rights Reserved
        </CopyRight>
      </BottomSection>
    </Container>
  );
};

export default withRouter(Footer);
