import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout';
import Dropdown from './Dropdown';
import TableData from './TableData';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 50px;
  margin-top: 70px;
  background: #F3F3F3;
  padding: 5% 12% 5% 12%;
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: scale(2);
  margin-right: 30px;
`;
const Heading2 = styled.h2`
  font-size: 26px;
  line-height: 28px;
  text-align: left;
  margin: 0;
  font-weight: 100;
  font-family: 'PT Sans', sans-serif;
`;
const Table = styled.table`
  background: white;
  font-size: 14px;
`;
const Th = styled.th`
  padding: 20px;
  text-align: center;
  background: #E0E0E0;
  border-right: 1px solid white;
  &:last-child {
    border-right: 0;
  }
`;
const Td = styled.td`
  padding: 20px;
  border-right: 1px solid #f5f5f5;
  border-bottom: 1px solid #ededed;
  &:last-child {
    border-right: 0;
    border-bottom: 0;
  }
`;

function createRow(rowIndex) {
  let row = 
    <tr>
      <Td><a href="#">{TableData[rowIndex].heading}</a><br />{TableData[rowIndex].description}</Td>
      <Td>{TableData[rowIndex].type}</Td>
      <Td>{TableData[rowIndex].os}</Td>
      <Td>{TableData[rowIndex].version}</Td>
      <Td>{TableData[rowIndex].date}</Td>
    </tr>
  return row;
}

const DriverPage = () => {
  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5'];
  const filterOptions = ['Filter 1', 'Filter 2', 'Filter 3', 'Filter 4'];
  const tableHeadings = ['Description', 'Type', 'OS', 'Version', 'Date'];
  const tableRows = [];

  for (let i = 0; i < TableData.length; i += 1) {
    tableRows.push(createRow(i))
  }

  return (
    <Layout>
      <Wrapper>
        <Section style={{ justifyContent: "flex-start", alignItems: "center", gap: "20px", paddingLeft: "30px" }}>
          <Icon src="processor-icon.png" alt="processor-icon" />
          <Heading2 style={{ textAlign: "left" }}>Downloads for Processors</Heading2>
        </Section>
        <Section>
          <div style={{ display: "flex", alignItems: "center", gap: "20px", width: "70%" }}>
            <label style={{ marginTop: "10px" }}>View by Product</label><Dropdown options={options} width="500px" />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "20px", width: "50%" }}>
            <label style={{ marginTop: "10px" }}>Filter by</label><Dropdown options={filterOptions} width="300px" />
          </div>
        </Section>
        <Table>
          <tr>
            {tableHeadings.map((label, index) => {
              return <Th key={index}>{label}</Th>
            })}
          </tr>
          { tableRows }
        </Table>
      </Wrapper>
    </Layout>
  )
}

export default DriverPage;
