import React from 'react';

export const Context = React.createContext();
export const StateProvider = (({ children }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  return (
    <Context.Provider
      value={{
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </Context.Provider>
  );
});
