import { useState } from "react";
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Form,
} from "react-bootstrap";
import styled from "styled-components";

const Heading2 = styled.h1`
  font-size: 2.5rem;
  line-height: 1.2;
  text-align: left;
  margin: 0;
  font-weight: 600;
  //font-family: 'PT Sans', sans-serif;
  padding-bottom: 8px;
  color: #010101;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

export default function SubscribeNewsletter(props) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      console.log(event.target);
    }

    setValidated(true);
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ borderBottom: "0px" }}></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={6} className="text-center">
              <img
                src="/subscribe.jpg"
                style={{ width: "120px", height: "120px" }}
              ></img>
            </Col>
            <Col xs={12} md={6}>
              <Heading2>Join Us</Heading2>
              <p>
                Subscribe our newsletter and stay up to date about the company!
              </p>
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mt-3 mb-3">
              <Col xs={12} md={12}>
                <InputGroup className="mb-3 mt-3">
                  <FormControl
                    name="email"
                    type="email"
                    placeholder="Enter your E-mail address..."
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    required
                  />
                  <Form.Control.Feedback tooltip type="invalid">
                    Please enter valid email!
                  </Form.Control.Feedback>
                  <Button
                    className="ml-3"
                    variant="outline-danger"
                    id="button-addon2"
                    type="submit"
                  >
                    Subscribe
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
