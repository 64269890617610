import React, { useEffect } from "react";
import "./App.css";
import styled from "styled-components";
import ErrorPage from "./components/404";
import AboutUs from "./components/AboutUs";
import HomePage from "./components/HomePage";
import HomePageRecent from "./components/HomePageRecent";
import ContactUs from "./components/ContactUs";
import Privacy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import Infrastructure from "./components/infrastructure";
import Blog from "./components/Blog";
import MyPal from "./components/MyPal";
import Jobs from "./components/Jobs/Jobs";
import Pricing from "./components/Pricing";
import AllBlogs from "./components/AllBlogs";
import ScrollTop from "./components/ScrollTop";
import Centiment from "./components/centiment";
import Downloads from "./components/Downloads";
import Hardware from "./components/Hardware/Hardware";
import { StateProvider } from "./components/Context/globalContextProvider";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Bitrise from "./components/Bitrise/Bitrise";
import Products from "./components/Products/Products";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Cart from "./components/Cart";
import { useDispatch, useSelector } from "react-redux";
import { createCheckout } from "./redux/actions/checkoutActions";
import { getProducts } from "./redux/actions/productActions";
import { getShop } from "./redux/actions/shopActions";

/* const dispatch = useDispatch();
  const { isCartOpen, checkout } = useSelector((state) => ({
    isCartOpen: state.cart.isCartOpen,
    checkout: state.checkout,
  }));
  const handleCartClose = () => {
    dispatch(cartClose());
  }; */

const Wrapper = styled.div``;






function App() {
  return (
    <StateProvider>
      <Wrapper className="App">
        <Router>
          <ScrollTop>
            <Switch>
            <Route path="/" exact component={HomePageRecent} />
            </Switch>
          </ScrollTop>
        </Router>
      </Wrapper>
    </StateProvider>
  );
}











// function App() {
// // function App({ store, persistor, client }) {
//   // useEffect(() => {
//   //   // buildClient() is synchronous, so we can call all these after!
//   //   client.product.fetchAll().then((res) => {
//   //     store.dispatch(getProducts(res));
//   //   });
//   //   client.checkout.create().then((res) => {
//   //     store.dispatch(createCheckout(res));
//   //   });
//   //   client.shop.fetchInfo().then((res) => {
//   //     store.dispatch(getShop(res));
//   //   });
//   // }, []);
//   return (
//     // <Provider store={store}>
//     //   <PersistGate /* loading={<Preloader />} */ persistor={persistor}>
//         <StateProvider>
//           <Wrapper className="App">
//             <Router>
//               <ScrollTop>
//                 <Switch>
//                   <Route path="/" exact component={HomePageRecent} />
//                   <Route path="/home" exact component={HomePage} />
//                   {/* <Route path="/about-us" exact component={AboutUs} /> */}
//                   {/* <Route path="/contact-us" exact component={ContactUs} /> */}
//                   {/* <Route path="/downloads" exact component={Downloads} /> */}
//                   {/* <Route path="/privacy-policy" exact component={Privacy} /> */}
//                   <Route path="/pricing" exact component={Pricing} />
//                   {/* <Route path="/jobs" exact component={Jobs} /> */}
//                   {/* <Route path="/cookie-policy" exact component={CookiePolicy} /> */}
//                   {/* <Route path="/hardware" exact component={Hardware} /> */}
//                   {/* <Route path="/blog" exact component={Blog} /> */}
//                   {/* <Route path="/infrastructure" exactcomponent={Infrastructure} /> */}
//                   {/* <Route path="/helpcenter" exact component={Centiment} /> */}
//                   {/* <Route path="/all-blogs" exact component={AllBlogs} /> */}
//                   {/* <Route path="/bitrise" exact component={Bitrise} /> */}
//                   {/* <Route path="/mypal" exact component={MyPal} /> */}
//                   {/* <Route path="/products" exact component={Products} /> */}
//                   {/* <Route component={ErrorPage} />  404 Redirect Page Here  */}
//                 </Switch>
//               </ScrollTop>
//             </Router>
//             <Cart />
//           </Wrapper>
//         </StateProvider>
//     //   </PersistGate>
//     // </Provider>
//   );
// }

export default App;
