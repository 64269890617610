import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout';

const Wrapper = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 20% 5% 20%;
  @media (max-width: 780px) {
    padding: 1%;
  }
`;
const Blog = styled.div`
  display: flex;
  flex-direction: column;
  width: 60vw;
  max-width: 1200px;
  @media (max-width: 780px) {
    width: 90vw;
  }
`;
const BlogDate = styled.label`
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.5;
  color: black;
`;
const BlogLink = styled.a`
  text-transform: uppercase;
  white-space: nowrap;
  color: #D05A3E;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    color: #D05A3E;
    cursor: pointer;
    text-decoration: none;
  }
`;
const BlogHeading = styled.h1``;
const BlogPoster = styled.h5`
  opacity: 0.5;
  color: black;
`;
const ImageDiv = styled.div`
  height: 350px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 780px) {
    height: 220px;
    width: auto;
  }
`;
const BlogImage = styled.img`
  width: 60vw;
  max-width: 100%;
  object-fit: cover;
  @media (max-width: 780px) {
    width: 100%;
    max-width: 100%;
  }
`;
const Blur = styled.div`
  z-index: 1;
  width: 120%;
  height: 150px;
  position: relative;
  margin-bottom: -150px;
  background-color: trnasparent;
  transform: translate(-8%, -140px);
  box-shadow: inset 0px -60px 50px 0px #FFFFFF;
  -moz-box-shadow: inset 0px -60px 50px 0px #FFFFFF;
  -webkit-box-shadow: inset 0px -60px 50px 0px #FFFFFF;
`;
const Button = styled.button`
  border: 1px solid #D05A3E;
  outline: 0;
  width: 110px;
  color: #D05A3E;
  border-radius: 5px;
  background: white;
  white-space: nowrap;
  transition: 0.4s;
  padding: 7px 0px 7px 0px;
  margin: 25px 0px 25px 0px;
  &:hover {
    color: white;
    cursor: pointer;
    background: #D05A3E;
  }
  &:focus {
    outline: 0;
  }
`;
const HR = styled.hr`
  border: 0;
  border-bottom: 1px solid black;
  background: white;
  opacity: 0.5;
  width: 100%;
  margin-bottom: 10%;
`;

const blogHeadings = [
  'Supporting the 2018 Bridges Gala',
  'Google I/O 2018',
  'Capital One Hosts About Objects for Swift 4 Presentation',
  'Google Home, Google I/O, and More',
  'Educating Sales Engineers on iOS Development',
  'Transitioning to Swift Class Finds Receptive Audience Among Objective-C Developers',
];
const blogImages = [ 'blog1.jpeg', 'blog2.png', 'blog3.jfif', 'blog4.jpg', '', '' ];
const blogDates = [
  'June 29, 2018',
  'May 29, 2018',
  'March 8, 2018',
  'May 24, 2017',
  'May 15, 2017',
  'March 16, 2017',
];
const blogPosters = [
  'Posted by About Objects',
  'Posted by About Objects',
  'Posted by About Objects',
  'Posted by About Objects',
  'Posted by About Objects',
  'Posted by About Objects',
];

const AllBlogs = () => {
  return (
    <Layout>
      <Wrapper>
        {blogHeadings.map((heading, index) => {
          return (
            <Blog>
              <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                <BlogDate>{blogDates[index]}</BlogDate>
                <BlogDate>|</BlogDate>
                <BlogLink>Comments</BlogLink>
              </div>
              <BlogHeading>{heading}</BlogHeading>
              <BlogPoster>{blogPosters[index]}</BlogPoster><br /><br />
              {blogImages[index] === '' ? <p>BLOG CONTENT HERE</p> :
                <div>
                  <ImageDiv>
                    <BlogImage src={blogImages[index]} alt={`${blogHeadings[index]}-image`} />
                  </ImageDiv>
                  <Blur />
                </div>
              }
              <Button>Read More</Button>
              <HR />
            </Blog>
          );
        })}
        <Button>Load More</Button>
      </Wrapper>
    </Layout>
  );
}

export default AllBlogs;
