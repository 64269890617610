import React from 'react';
import styled from 'styled-components';
import { PageTitle } from '../ReusableItems/reusableItems';
import Profile from './Profile';
import Layout from '../Layout';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  font-family: 'PT Sans', sans-serif;
  min-height: 100vh;
  text-align: left;
  margin: 6% 10% 10% 10%;
  @media (max-width: 780px) {
    margin: 5%;
  }
`;
const Title = styled(PageTitle)`
  opacity: 1;
  color: #f2f2f2;
  background: #333333;
`;
const Heading1 = styled.h1`
  font-size: 34px;
  line-height: 36px;
  margin: 0;
  font-weight: 300;
`;
const Heading5 = styled.h5`
  font-size: 20px;
  line-height: 22px;
  margin: 0;
  font-weight: 300;
`;
const BodyHeading = styled.p`
  font-size: 22px;
  line-height: 24px;
  opacity: 0.6;
`;
const BodyText = styled.p`
  font-size: 17px;
  line-height: 24px;
  opacity: 0.6;
`;
const MissionSection = styled.div`
  margin: 0%;
`;
const ProfileSection = styled.div`
  margin: 0%;
`;

const ProfileImages = [ 'profile1.jpg', 'profile2.jpg', 'profile3.jpg' ];
const ProfilesNames = [ 'Robert Lill', 'Michael Arnett', 'Robert Tabit' ];
const ProfileRanks = [ 'President & Chief Architect', 'COO & Senior Systems Architect', 'Director of Enterprise Development' ];
const Paragraph1 = [
  'Rob has over twenty-five years of experience working with big data and analytics using best-in-class technologies and best practice based implementations. Rob has served as the lead architect on custom solutions for several of BNL’s clients, including: the Department of Health and Human Services (HHS), the Food and Drug Administration (FDA), the Centers for Disease Control and Prevention (CDC), and the United States Census Bureau. Rob’s reputation as a strong architect and a focused leader has helped BNL Consulting become one of the top providers of professional services to its clients.',
  'Mike’s career has spanned more than twenty years of software development. Over that time, he has crafted solutions using a broad range of languages and technologies, but specializes in Enterprise Java solutions and implementations. Mike provides technical guidance and assists in the successful delivery of enterprise systems for both corporate and public sector clients. He focuses on identifying creative solutions for integration and implementation issues (such as security and privacy concerns) commonly occurring in large enterprise systems comprised of heterogeneous technologies and constrained by inflexible business realities.',
  'Robert has more than twenty years of experience building Enterprise Java solutions in a diverse set of verticals including government, e-commerce, agriculture, law enforcement, and transportation. As the Director of Enterprise Development, Robert currently focuses on leading BNL’s internal product development efforts while also acting as the technical lead for BNL’s U.S. Census Bureau projects. He is involved with building commercial software products that complement BNL’s leadership in data analytics, data visualization, and enterprise integration.'
];
const Paragraph2 = [
  'Prior to BNL, Rob spent 8 years at the SAS Institute as a Technical Architect and Systems Engineer. He has over two decades of experience in application development, with a strong expertise in health care data analysis and reporting. Rob is well-versed in several different programming languages and holds a degree in Political Science from The University of North Carolina at Wilmington.',
  'Mike joined BNL Consulting in 2008 after working as a contractor for several years at the Centers for Disease Control and Prevention (CDC) on the Lab Response Network’s BioWatch initiative. Additionally, Mike is credited as one of the co-inventors on a system patent held by the Health Care Incentives Improvement Initiative. Mike holds a Bachelor of Arts in Computer Science from the University of Georgia.',
  'Robert joined BNL Consulting in 2011 after an extended career at IBM where he worked in both software consulting and software sales with an emphasis on Enterprise Content Management and Enterprise Java Development. He is also credited as a co-inventor on a patent (US7962159) held by IBM which details a method and system for receiving data on portable devices. Robert holds a B.S. in Computer Science and a M.S. in Computer Systems Management, both from the University of Maryland.'
];

const AboutUs = () => {
  let profiles = [];
  for (let i = 0; i < ProfilesNames.length; i += 1) {
    profiles.push (
      <div>
        <Profile
          url={ProfileImages[i]}
          name={ProfilesNames[i]}
          rank={ProfileRanks[i]}
          para1={Paragraph1[i]}
          para2={Paragraph2[i]}  
        />
      </div>
    );
  }
  return (
    <Layout variant='black'>
      <Title>
        ABOUT US
      </Title>
      <Wrapper>
        <MissionSection>
          <Heading1>Our Mission</Heading1>
          <BodyText>At Dermveda, we believe that skin health reflects your overall well-being. Our experts are committed to empowering you on your personal journey to harmonise your mind, body and skin. We bridge thousands of years of traditional knowledge with latest science to give you personalised solutions for Better Skin, Better Health, Better YOU.</BodyText>
          <br /><br />
          <Heading5>Contact Us</Heading5>
          <BodyText>Have questions, comments, or suggestions? Shoot us an e-mail at info@derveda.com.</BodyText>
        </MissionSection>
        <ProfileSection>
          <BodyHeading>We are industry leaders in data analytics, data visualization, and enterprise integration, and we're proud to share with you the story of our company and the individuals who comprise our core leadership team.</BodyHeading>
          <BodyText>Founded in 2007, BNL Consulting began by architecting and implementing small to enterprise level business intelligence systems. At our core we are still dedicated to providing SAS-centric services, but over the past decade we have expanded our offerings to include custom analytics platforms, highly interactive user experiences, and seamless enterprise integration solutions. While the relationships we have cultivated with our clients give us a wide spectrum of experience, we have demonstrated an especially successful track record of providing solutions to the Public Sector, Sports and Entertainment industries, and the Health and Life Sciences fields.</BodyText>
          {profiles}
        </ProfileSection>
        <hr />
      </Wrapper>
    </Layout>
  );
}

export default AboutUs;
