





import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./assets/css/style.scss";
import CookieConsent from "react-cookie-consent";
import configureStore from "./redux/store/store";
import Client from "shopify-buy";
import { createClient } from "./redux/actions/appActions";

// const { store, persistor } = configureStore();
// const client = Client.buildClient({
//   storefrontAccessToken: "dd4d4dc146542ba7763305d71d1b3d38",
//   domain: "graphql.myshopify.com",
// });
// store.dispatch(createClient(client));

ReactDOM.render(
  <>
    {/* <App store={store} persistor={persistor} client={client} /> */}
    <App/>

  </>,
  document.getElementById("root")
);

// {/* <CookieConsent
// location="bottom"
// cookieName="techlabzCookieName"
// style={{
//   background: "#475564",
//   paddingLeft: "50px",
//   paddingRight: "50px",
//   fontSize: "18px",
//   zIndex: "200",
// }}
// buttonStyle={{ color: "#4e503b", fontSize: "18px" }}
// expires={150}
// enableDeclineButton
// flipButtons
// /* acceptOnScroll={true}
// acceptOnScrollPercentage={50} */
// onAccept={(acceptedByScrolling) => {
//   if (acceptedByScrolling) {
//     console.log("accept by scroll!");
//   } else {
//     console.log("accept by click!");
//   }
// }}
// onDecline={() => {
//   console.log("reject!");
// }}
// /* debug={true} */
// >
// This website uses cookies to enhance the user experience.
// </CookieConsent> */}