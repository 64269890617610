import React from "react";
import Layout from "./../Layout/index";
import { Container, Row, Col, Card } from "react-bootstrap";
import Images from "../../Constants/Images";
import { Link } from "react-router-dom";
import styled from "styled-components";

function HomePage() {
  const Wrapper = styled.section`
    width: 75%;
    max-width: 960px;
    margin-left: 10%;
    font-family: "TT Norms Pro Bold", sans-serif;
  `;
  const Image1 = [
    {
      _id: 1,
      image: Images.LogoImage,
    },
    {
      _id: 2,
      image: Images.LogoImage2,
    },
    {
      _id: 3,
      image: Images.LogoImage3,
    },
    {
      _id: 4,
      image: Images.LogoImage4,
    },
    {
      _id: 5,
      image: Images.LogoImage5,
    },
  ];

  const Image2 = [
    {
      _id: 1,
      image: Images.LogoImage6,
    },
    {
      _id: 2,
      image: Images.LogoImage7,
    },
    {
      _id: 3,
      image: Images.LogoImage8,
    },
    {
      _id: 4,
      image: Images.LogoImage9,
    },
    {
      _id: 5,
      image: Images.LogoImage10,
    },
  ];

  const data1 = [
    {
      _id: 1,
      heading: "Smart Ink",
      paragraph:
        "Scribble's technologically advanced ink is lightfast and water-resistant – no matter the weather, it won't fade away.",
    },
    {
      _id: 2,
      heading: "Rechargeable Battery",
      paragraph:
        "Charged quickly via micro-USB cable, Scribble's lightweight, lithium-ion battery gives you 15 hours of unlimited creating power",
    },
    {
      _id: 3,
      heading: "Cross-Platform Compatibility",
      paragraph:
        "You can easily transfer your custom and captured colors into Photoshop or CorelDRAW, for use in all your projects.",
    },
  ];
  const data2 = [
    {
      _id: 1,
      heading: "Capacitive Rubber Tips",
      paragraph:
        "The Scribble Stylus comes with two different tips, for precise, lag-free drawing or writing on your mobile device. You can make broad strokes with the soft rubber tip, or aim for precision with the harder, finer tip.",
    },
    {
      _id: 2,
      heading: "Dedicated Next/Previous Button",
      paragraph:
        "The next/previous button makes it easy to quickly switch colors on the fly, cycling through your entire collection.",
    },
    {
      _id: 3,
      heading: "Universal Design",
      paragraph:
        "Your stylus will play nicely with other apps! Designed to universal standards, the Scribble software is open to all developers.",
    },
  ];

  const data3 = [
    {
      _id: 1,
      heading: "Always Know How Much Ink You Have",
      paragraph:
        "One touch is all it takes to check the levels in your Scribble Ink Pen.",
    },
    {
      _id: 2,
      heading: "Organize and Share Every Color You Capture",
      paragraph:
        "Because the app remembers all your colors, you can easily name, sort and share them.",
    },
    {
      _id: 3,
      heading: "Easily Connect and Sync with Your Device",
      paragraph:
        "Just pair the app with your Scribble Pen or Stylus, and you're ready to create!",
    },
    {
      _id: 4,
      heading: "Fast, Precise Color Code Conversions",
      paragraph:
        "You'll get RGB, CMYK, and HEX codes for every color you capture.",
    },
    {
      _id: 5,
      heading: "Work with Custom Colors",
      paragraph:
        "The Scribble app lets you capture, create and save as many colors as you like.",
    },
  ];

  const workCard = [
    {
      _id: 1,
      image: Images.Sen,
      name: "RGB Color Sensor",
      text:
        "The built-in color sensor lets you easily capture any color – simply point it at an object or surface and press the button! Draw or write with it right away, or save it to sync with your mobile devices.",
    },
    {
      _id: 2,
      image: Images.Draw,
      name: "Multiple Drawing Tips",
      text:
        "The Scribble Ink Pen comes with three different sizes of drawing tip, allowing you the freedom to express yourself as neatly or boldly as you like. Scribble is perfect for both drawing and accurate writing.",
    },
    {
      _id: 3,
      image: Images.Ink,
      name: "Refillable Ink Cartridge",
      text:
        "Scribble's ink cartridge connects to a mixer and dispenser that exactly recreates the color you have scanned. Each cartridge comes with 30 miles of ink, and new cartridges cost less than $10!",
    },
  ];

  const cardData = [
    {
      _id: 1,
      heading: "Ink Pen",
      para1: "Write or draw in any color you can think of",
      para2: "RGB sensor to scan and store colors",
      para3: "Unapologetically awesome",
      image: Images.Pen1,
      Price: "$400",
      bottomText: "Free Worldwide Shipping",
    },
    {
      _id: 2,
      heading: "Scribble Ink Pen",
      para1: "Write or draw in any color you can think of",
      para2: "RGB sensor to scan and store colors",
      para3: "Unapologetically awesome",
      image: Images.Pen2,
      Price: "$400",
      bottomText: "Free Worldwide Shipping",
    },
  ];

  const listData = [
    {
      _id: 1,
      list: "Compatibility",
      span: "iOS 7+, Android 4.0+",
    },
    {
      _id: 2,
      list: "Connectivity",
      span: "Bluetooth, USB",
    },
    {
      _id: 3,
      list: "Battery Life",
      span: "15 hours",
    },
    {
      _id: 4,
      list: "Color Sensor",
      span: "RGB Sensor",
    },
    {
      _id: 5,
      list: "Tip",
      span: "3 tip sizes to control stroke weight",
    },
    {
      _id: 6,
      list: "Dimension",
      span: "169 x 16.8mm (6.65 x 0.66 in.)",
    },
  ];

  return (
    <Layout>
      {/* <Wrapper style={{ textAlign: "left" }}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h2>
          Please go to;
          <ul>
            <li>
              <Link to="/404">/404</Link>
            </li>
            <li>
              <Link to="about-us">/about-us</Link>
            </li>
            <li>
              <Link to="contact-us">/contact-us</Link>
            </li>
            <li>
              <Link to="drivers">/drivers</Link>
            </li>
            <li>
              <Link to="privacy-policy">/privacy-policy</Link>
            </li>
            <li>
              <Link to="cookie-policy">/cookie-policy</Link>
            </li>
            <li>
              <Link to="pricing">/pricing</Link>
            </li>
            <li>
              <Link to="blog">/blog</Link>
            </li>
            <li>
              <Link to="infrastructure">/Infrastructure</Link>
            </li>
            <li>
              <Link to="all-blogs">/all-blogs</Link>
            </li>
            <li>
              <Link to="mypal">/mypal</Link>
            </li>
          </ul>
        </h2>
      </Wrapper> */}
      <main id="page_content">
        <section
          className="home_banner"
          // style={{ backgroundImage: `url(${Images.Home_Banner})` }}
          style={{ backgroundColor: "#333333" }}
        >
          <Container>
            <Row>
              <Col xs={12}>
                <h1 className="hero-heading">BLACKBOX</h1>
                <p className="hero-subheading">
                  One-Thousand Devices in One
                </p>

                <div style={{position:"relative", left: "250px"}}>
                  <img
                    src={Images.HomeBannerImage}
                    className="img-fluid"
                    alt="Pen Preview"
                  />
                </div>

                <div className="hero-buttons">
                  {/* <a href="#" className="btn btn-default"> */}
                  <a data-fancybox="" href="#" className="btn btn-primary">
                    Pre-Order  <i class='fa fa-long-arrow-right'></i>
                  </a>
                  {/* <a data-fancybox="" href="#" className="btn btn-primary">
                    <i class='fa fa-long-arrow-right'></i> Watch Video
                  </a> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="world"
          style={{ backgroundImage: `url(${Images.HomeBannerImage2})` }}
        >
          <Container>
            <h2>Now the World is Your Palette</h2>
            <p>
              Easy to use and comfortable to work with, Scribble puts all the
              colors of
              <br /> the world in your hands. Scan any color and start drawing
              or writing
              <br /> with it instantly. Scribble stores your colors too, so you
              can upload,
              <br /> share and use them wherever, whenever you want.
            </p>
          </Container>
        </section>
        <section className="scribble">
          <Container>
            <h1>How does Scribble Work?</h1>
            <Row>
              {workCard.map((data, index) => (
                <Col lg={4} md={4} xs={12} key={`key-${index}-${data._id}`}>
                  <Card>
                    <Card.Body>
                      <img src={data.image} alt="" className="img-fluid" />
                      <div className="card-text">
                        <h4>{data.name}</h4>
                        <p>{data.text}</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <section className="order">
          <Container>
            <div className="cover">
              <p>BE ONE OF THE FIRST TO OWN THIS REVOLUTIONARY TECHNOLOGY!</p>
              <a href="#" className="btn">
                PRE ORDER NOW
              </a>
            </div>
          </Container>
        </section>
        <section className="last_pen">
          <Container>
            <Row>
              <Col lg={6} md={6} xs={12} className="align-self-center">
                <h1>The Last Pen You'll Ever Need to Buy</h1>
                <p>
                  Scribble's technologically advanced ink is lightfast and
                  water-resistant – no matter the weather, it won't fade away.
                </p>
                {data1.map((data, index) => {
                  return (
                    <div key={index}>
                      <h4>{data.heading}y</h4>
                      <p>{data.paragraph}</p>
                    </div>
                  );
                })}
              </Col>
              <Col lg={6} md={6} xs={12}>
                <img src={Images.Pen} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="inspired"
          style={{ backgroundImage: `url(${Images.HomeBannerImage3})` }}
        >
          <Container>
            <h2>With Scribble You Can Be Inspired Anywhere</h2>
            <p>
              Whether you are an artist or designer, looking for inspiration,
              want to share a color with a client, need to keep your fashion
              sense sharp, or are just someone who loves colors and drawing,
              Scribble is for you
            </p>
            <div className="text-center">
              <a href="#" className="btn">
                WATCH VIDEO
              </a>
            </div>
          </Container>
        </section>
        <section className="ink_pen">
          <Container fluid>
            <Row>
              <Col lg={6} md={6} xs={12} className="align-self-center">
                <img src={Images.LargePen} alt="" className="img-fluid" />
              </Col>

              <Col lg={4} md={5} xs={12}>
                <h3>Specifications</h3>
                <h2>Scribble Ink Pen</h2>

                <ul className="list-group list-group-flush">
                  {listData.map((data, index) => {
                    return (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        {data.list}
                        <span>{data.span}</span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="paper">
          <Container>
            <Row>
              <Col lg={6} md={6} xs={12} className="align-self-center">
                <h1>Work on More than Just Paper</h1>

                {data2.map((data, index) => {
                  return (
                    <div key={index}>
                      <h4>{data.heading}</h4>
                      <p>{data.paragraph}</p>
                    </div>
                  );
                })}
              </Col>
              <Col lg={6} md={6} xs={12}>
                <img src={Images.LargePen2} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="specification">
          <Container>
            <Row style={{ color: "white" }}>
              <Col lg={6} md={6} xs={12} className="align-self-center">
                <h3>Specifications</h3>
                <h2>Scribble Ink Pen</h2>

                <ul className="list-group list-group-flush">
                  {listData.map((data, index) => {
                    return (
                      <>
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {data.list}
                          <span>{data.span}</span>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </Col>
              <Col lg={6} md={6} xs={12} className="align-self-center">
                <img src={Images.LargePen3} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="imagination"
          style={{ backgroundImage: `url(${Images.LargePen4})` }}
        >
          <Container>
            <Row>
              <Col xs={12}>
                <h1>Power Your Imagination With the Free Scribble App</h1>
                <h3>
                  Intuitively Designed and <br />
                  Simple to Use
                </h3>
                <p>
                  The Scribble app makes it easy to write, draw,
                  <br /> sketch, paint and share on your mobile device.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section
          className="home"
          style={{ backgroundImage: `url(${Images.LargeImage})` }}
        >
          <Container>
            <Row>
              <Col lg={6} md={6} xs={12} className="align-self-center">
                {data3.map((data, index) => {
                  return (
                    <div key={index}>
                      <h4>{data.heading}y</h4>
                      <p>{data.paragraph}</p>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </section>
        <section className="featured">
          <Container>
            <h3>As featured in...</h3>
            <Row className="justify-content-center">
              {Image1.map((data, index) => {
                return (
                  <Col lg="auto" xs={12} key={index}>
                    <img src={data.image} alt="" />
                  </Col>
                );
              })}
            </Row>
            <Row className="justify-content-center">
              {Image2.map((data, index) => {
                return (
                  <Col lg="auto" xs={12} key={index}>
                    <img src={data.image} alt="" />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </section>
        <section className="mail">
          <Container>
            <div className="cover">
              <p>HAVE SOME QUESTIONS? EMAIL US</p>
              <a href="#" className="btn">
                EMAIL US
              </a>
            </div>
          </Container>
        </section>
        <section className="both">
          <Container>
            <h2>Order one (or both) today!</h2>
            <Row className="justify-content-center">
              <Col lg={10} xs={12}>
                <Row>
                  {cardData.map((data, index) => {
                    return (
                      <>
                        <Col key={index} lg={6} md={6} xs={12}>
                          <Card>
                            <Card.Body>
                              <div className="card-text">
                                <div className="card-text">
                                  <h4>
                                    <strong>Scribble</strong> {data.heading}
                                  </h4>
                                  <p>{data.para1}</p>
                                  <p>{data.para2}</p>
                                  <p>{data.para3}</p>
                                </div>
                                <img
                                  src={data.image}
                                  alt=""
                                  className="img-fluid img"
                                />
                              </div>

                              <nav className="nav">
                                <a className="bla" href="#"></a>
                                <a className="whi" href="#"></a>
                                <a className="gre" href="#"></a>
                                <a className="blu" href="#"></a>
                                <a className="gren" href="#"></a>
                              </nav>

                              <div className="text-center">
                                <a href="#" className="btn">
                                  PRE ORDER
                                </a>
                              </div>
                              <p className="price">Price: $400</p>
                              <p className="price">Free Worldwide Shipping</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  );
}

export default HomePage;
