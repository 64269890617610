import React from "react";
import styled from "styled-components";
import { BsChevronCompactDown } from 'react-icons/bs';
import { BsChevronCompactUp } from 'react-icons/bs';

const Wrapper = styled.div`
  display: flex;
  width: ${(props) => props.width};
  background: white;
  border-radius: 4px;
`;
const DropDownContainer = styled.div``;
const DropDownHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  opacity: 0.8;
  background: white;
  margin-bottom: 1px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: -1px;
  padding: 10px 15px 10px 15px;
  width: ${(props) => props.width};
  border: ${(props) => props.isOpen ? "2px solid black" : "1px solid lightgray"};
`;
const DropDownListContainer = styled.div``;
const DropDownList = styled.ul`
  margin: 0;
  padding: 0;
  opacity: 0.8;
  background: white;
  box-sizing: border-box;
  width: ${(props) => props.width};
  -webkit-box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 20px -10px rgba(0,0,0,0.75);
`;
const ListItem = styled.li`
  padding: 8px 15px 8px 15px;
  list-style: none;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 2px solid #F3F3F3;
`;
const Arrow = styled.div`
  opacity: 0.8;
`;

function Dropdown(props) {
  const { options, width } = props;
  const [ isOpen, setIsOpen ] = React.useState(false);
  const [ selectedOption, setSelectedOption ] = React.useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <Wrapper width={width}>
      <DropDownContainer>
        <DropDownHeader isOpen={isOpen} onClick={ toggling } width={width}>
          { selectedOption || "Products"}
          { isOpen ? <Arrow><BsChevronCompactUp /></Arrow> : <Arrow><BsChevronCompactDown /></Arrow> }
        </DropDownHeader>
        { isOpen && (
          <DropDownListContainer>
            <DropDownList width={width}>
              { options.map(option => (
                <ListItem onClick={ onOptionClicked(option) } key={ Math.random() }>
                  { option }
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Wrapper>
  );
}

export default Dropdown;