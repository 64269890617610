import React from 'react';
import styled from "styled-components";
import Layout from '../Layout';

const Container = styled.section`
    position: relative;
    top: 0;
    bottom: 0;
    display: flex;
    margin-top: 0;
    padding-top: 144px;
    padding-bottom: 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 941px;
    &:before {
        content: " ";
        display: table;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-column-end: 2;
        grid-row-end: 2;
    }
`;
const Wrapper = styled.section`
  display: block;
    width: 75%;
    max-width: 960px;
    margin-top: 0;
    font-family: 'TT Norms Pro Bold', sans-serif;
    text-align: left;
`;
const Heading1 = styled.h1`
  font-size: 67px;
  margin: 0;
  font-family: 'TT Norms Pro Bold', sans-serif;
  color: #333333;
  font-weight: 500;
`;
const Heading2 = styled.h1`
  margin-top: 80px;
    margin-bottom: 24px;
    font-size: 47px;
    line-height: 1.4em;
    font-weight: 700;
    text-align: left;
    text-transform: none;
    opacity: 0.6;
`;
const Tag = styled.p`
    color: #616161;
    line-height: 1.5em;
  font-size: 16px;
  font-family: 'TT Norms Pro ExtraBold', sans-serif;
`;
const Anchor = styled.a`
    font-size: 16px;
    color: black;
    text-decoration: italic;
`;
const BodyHeading = styled.p`
  margin-top: 80px;
    margin-bottom: 24px;
    opacity: 0.6;
    font-size: 47px;
    line-height: 1.4em;
    font-weight: 700;
    text-align: left;
    text-transform: none;
`;
const BodyText = styled.p`
  font-size: 16px;  
  margin-bottom: 24px;
    line-height: 1.5em;
    font-family: 'TT Norms Pro Medium', sans-serif;
    color: #616161;
`;
const Heading3 = styled.h3`
    font-size: 30px;
    line-height: 27px;
    font-weight: 700;
    text-align: left;
    text-transform: none;
    margin-bottom: 15px;
    opacity: 0.6;
`;
const ListHeading = styled.p`
  font-size: 16px;  
    color: #616161;
    line-height: 1.5em;
    font-weight: 700;
`;
const OrderedList = styled.ol`
    
`;
const ListItem = styled.li`
  font-size: 16px; 
    line-height: 1.5em;
    font-family: 'TT Norms Pro Medium', sans-serif;
    color: #616161;
`;
const UnorderedList = styled.ul`
font-size: 15px;
`;
const StrongText = styled.strong`
    font-weight: 900;
    font-family: 'TT Norms Pro ExtraBold', sans-serif;
`;
// const Heading5 = styled.h5`
//   font-size: 20px;
//   line-height: 22px;
//   margin: 0;
//   font-weight: 300;
// `;

const PrivayPolicy = () => {
    return (
        <Layout>
            <Container>
            <Wrapper>
                <Heading1>Privacy Notice</Heading1>
                <Tag>Version: 1.2</Tag>
                <BodyText>Published on the following date: October 2, 2019.</BodyText>
                <BodyText>This Privacy Notice<StrongText> </StrongText>(<StrongText> "Privacy
                    Notice" </StrongText>)<StrongText> </StrongText>provides you with information regarding the
                    processing of your personal data carried out by <StrongText>Bitrise Limited </StrongText>(registered
                    seat: 3 Waterhouse Square, 138-142 Holborn, London, England, EC1N 2SW); email: <Anchor
                        href="mailto:legal@bitrise.io"><em>letsconnect@bitrise.io</em></Anchor>)<StrongText> </StrongText>(<StrongText> "Controller"
                        , "We" </StrongText>or<StrongText> "Us" </StrongText>)<StrongText> </StrongText>via the
                    bitrise.io website (<StrongText> "Website" </StrongText>)<StrongText> </StrongText>and the Bitrise
                    application ( <StrongText>"Application"
                        ,</StrongText> or <StrongText>"Services" </StrongText>)<StrongText> </StrongText>provided by Us.</BodyText>
                <BodyText>Please be aware that no data protection officer is operating in relation to data processing
                    carried out by Us.</BodyText>
                <BodyText>‍</BodyText>
                <Heading2>Definitions used in this Privacy Notice</Heading2>
                <h4>‍</h4>
                <BodyText>In this section you can find the definitions for the purpose of this Privacy
                    Notice.</BodyText>
                <BodyText><StrongText>"Personal Data" </StrongText>means any information relating to you through which
                    We can identify you directly or indirectly; it means in particular your name, identification number,
                    location data, online identifier or one or more factors specific to the physical, physiological,
                    genetic, mental, economic, cultural or social identity of yours.</BodyText>
                <BodyText><StrongText>"Data Processing" </StrongText>means any operation or set of operations which is
                    performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as
                    collection, recording, organization, structuring, storage, adaptation or alteration, retrieval,
                    consultation, use, disclosure by transmission, dissemination or otherwise making available,
                    alignment or combination, restriction, erasure or destruction.</BodyText>
                <BodyText><StrongText>"You" </StrongText>means any user of the website or the application whose data is
                    processed by Us.</BodyText>
                <BodyText><StrongText>"Service(s)" </StrongText>means all the services described in the Controller's
                    Terms of Use, available at <Anchor
                        href="https://www.bitrise.io/terms"><StrongText><em>https://www.bitrise.io/terms</em></StrongText></Anchor>,
                    without regard to whether it is provided through the Website or the Application.</BodyText>
                <BodyText><StrongText>"Data Processor" </StrongText>means a natural or legal person, public authority,
                    agency or other body which processes Personal Data on behalf of Us.</BodyText>
                <BodyHeading>I. Data Processing Principles</BodyHeading>
                <h4>‍</h4>
                <OrderedList role="list">
                    <ListItem>We process your Personal Data in compliance with all the relevant laws, fairly and in a
                        transparent manner.</ListItem>
                    <ListItem>We process your Personal Data only for specified and legitimate purposes as described in
                        this Privacy Notice.</ListItem>
                    <ListItem>We do not process your Personal Data which is not adequate, relevant or necessary for the
                        purposes set out in this Privacy Notice.</ListItem>
                    <ListItem>We do our best to keep your Personal Data accurate and up to date. We take every
                        reasonable step to ensure that inaccurate Personal Data is erased or rectified without
                        delay.</ListItem>
                    <ListItem>We keep your Personal Data in a form which enables Us to identify You for no longer than
                        needed, for the purposes for which your Personal Data is processed.</ListItem>
                    <ListItem>We process your Personal Data in a manner that ensures appropriate security, including
                        protection against unauthorized or unlawful processing and against accidental loss, destruction
                        or damage.</ListItem>
                </OrderedList>
                <BodyHeading>II. Why, and how are We processing your Personal Data?</BodyHeading>
                <BodyText>In most cases, We are processing your Personal Data in order to provide You with our Services.
                    However, other purposes may apply (e.g., to fulfill our statutory obligations). Please find detailed
                    information on the purpose and other relevant circumstances of processing categorized by the
                    purposes below.</BodyText>
                <BodyText>We will always process your Personal Data in compliance with European standards on data
                    protection. We provide an adequate level of protection to your Personal Data by E.U. standards, even
                    in cases when We transfer your Personal Data outside the European Economic Area.</BodyText>
                <BodyText>Please note, that in the event of any inquiries coming from state, administrative or
                    investigative authorities, We may be obligated to provide these authorities with your Personal
                    Data.</BodyText>
                <Heading3>a. Registration</Heading3>
                <BodyText><StrongText>The purpose of processing your Personal Data: </StrongText>Registration on the
                    Website and use of the Application offered on the website.</BodyText>
                <BodyText><StrongText>The legal basis for the processing: </StrongText>Your consent.</BodyText>
                <BodyText><StrongText>The categories of personal data concerned: </StrongText>Data provided by you, in
                    the course of the registration and database ID generated by Us to store your data.</BodyText>
                <BodyText><StrongText>The period for which the personal data will be stored: </StrongText>This data will
                    be deleted 30 days following the deletion of your profile.</BodyText>
                <Heading3>b. Using the Application</Heading3>
                <BodyText><StrongText>The purpose of processing your Personal Data: </StrongText>We process your
                    Personal Data while You are using our Services. This data is necessary for providing our Services to
                    You, enhancing our Services, payment purposes, as well as for information security
                    purposes.</BodyText>
                <BodyText><StrongText>The legal basis for the processing: </StrongText>The processing is necessary for
                    the performance of the service contract concluded between You and Bitrise.</BodyText>
                <BodyText><StrongText>The categories of personal data concerned: </StrongText>Data provided by You, in
                    the course of the registration, or while you are using our Services, logged event data, data
                    necessary for invoicing our services, and payment.</BodyText>
                <BodyText><StrongText>The period for which the personal data will be stored: </StrongText>This data will
                    be deleted 5 years following the deletion of your profile.</BodyText>
                <Heading3>c. Newsletters and direct marketing offerings</Heading3>
                <BodyText><StrongText>The purpose of processing your Personal Data: </StrongText>Informing our users
                    about Bitrise news, and our best offers.</BodyText>
                <BodyText><StrongText>The legal basis for the processing: </StrongText>Your consent.</BodyText>
                <BodyText><StrongText>The categories of personal data concerned: </StrongText>Data provided by
                    You.</BodyText>
                <BodyText><StrongText>The period for which the personal data will be stored: </StrongText>We will store
                    the relevant Personal Data until you revoke your consent (i.e., unsubscribe from our
                    newsletter).</BodyText>
                <Heading3>d. Customer support, and contacting us via e-mail.</Heading3>
                <BodyText><StrongText>The purpose of processing your Personal Data: </StrongText>Provide more efficient
                    and timely support to You when using our services, such as the support chatbox on our Website and
                    emails.</BodyText>
                <BodyText><StrongText>The legal basis for the processing: </StrongText>Your consent.</BodyText>
                <BodyText><StrongText>The categories of personal data concerned: </StrongText>Data provided by you, your
                    IP address, data collected from publicly available sources.</BodyText>
                <BodyText><StrongText>The period for which the personal data will be stored: </StrongText>This data will
                    be deleted 5 years following the day your profile is deleted. For messages sent by non-registered
                    users, we may also store the messages for 5 years following their delivery.</BodyText>
                <Heading3>e. Job application</Heading3>
                <BodyText><StrongText>The purpose of processing your Personal Data: </StrongText>Processing your job
                    application and related hiring matters.</BodyText>
                <BodyText><StrongText>The legal basis for the processing: </StrongText>Your consent.</BodyText>
                <BodyText><StrongText>The categories of personal data concerned: </StrongText>Data provided by you in
                    the course of the application process. You may be required to present a certificate of good conduct
                    (<StrongText>"erkölcsi bizonyítvány"</StrongText>) in the course of the application
                    process.</BodyText>
                <BodyText><StrongText>The period for which the personal data will be stored: </StrongText>In case of a
                    successful application the Bitrise Employee Privacy Notice will be applicable. In case of an
                    unsuccessful application, to the extent We process your Personal Data subject to the GDPR, We rely
                    on legitimate interests to process your data.</BodyText>
                <Heading3>F. Third Parties We Use</Heading3>
                <BodyText><StrongText>With respect to the Personal Data we describe in Sections II.A to II.E, to be able
                    to provide our services to you and to run our business, we share data with third parties from the
                    following categories:</StrongText></BodyText>
                <UnorderedList role="list">
                    <ListItem>Third parties which are involved in processing purchases, e.g., payment service providers,
                        etc.</ListItem>
                    <ListItem>Third parties which provide professional services to us, e.g., data center hosting
                        providers, advertising partners, marketing agencies, business consultants, web hosting
                        providers, etc.</ListItem>
                    <ListItem>Law enforcement and other government authorities, e.g. tax offices and customs.</ListItem>
                </UnorderedList>
                <BodyText><StrongText>Please see a list of these subprocessors and related information as to how we use
                    them at this </StrongText><Anchor
                    href="https://docs.google.com/spreadsheets/d/1QjkuJCWuIuu7HSj8OEUQ3eyYyl8iOE3m4FtXF-8mF2U/edit?ts=5d915e4b#gid=0"><StrongText>link</StrongText></Anchor><StrongText>.</StrongText></BodyText>
                <BodyHeading>III. Transferring your Personal Data within the Bitrise Group</BodyHeading>
                <BodyText>Please note, that We are using data processors for business and related support services, and
                    these data processors receive your Personal Data for the purpose of providing Us with such services.
                    Section II above describes the data processors We use.</BodyText>
                <BodyText>We may also transfer your Personal Data to current and future entities within the Bitrise
                    Group (currently consisting of Us, and Bitrise Inc., a U.S. company, registered seat: 3500 S DUPONT
                    HWY, DOVER, Kent, DE, 19901) for the Data Processing purposes listed in Section II.
                    above.</BodyText>
                <BodyText>We ensure that appropriate and suitable safeguards are in place when we transfer data within
                    the Bitrise group internationally, outside the EEA.</BodyText>
                <BodyText>For our vendors based in the U.S., we ensure that these vendors are part of the EU-U.S. and
                    Swiss-U.S. Privacy Shield Framework. For further information please see <Anchor
                        href="https://www.privacyshield.gov/welcome">https://www.privacyshield.gov/welcome</Anchor></BodyText>
                <BodyText>Bitrise Limited and Bitrise Inc. have concluded a model contract issued by the European
                    Commission to ensure that sufficient safeguards are in place on data protection for any data
                    transferred between these entities.</BodyText>
                <BodyHeading>IV. Your rights regarding the Data Processing carried out by Us</BodyHeading>
                <BodyText>You have the following rights regarding Data Processing carried out by Us in relation to your
                    Personal Data:</BodyText>
                <StrongText>
                    <UnorderedList role="list">right to access</UnorderedList>
                </StrongText>
                <BodyText>You have the right to request from Us access to your Personal Data processed by Us and obtain
                    information regarding the purpose of processing, what categories of Personal Data are processed, for
                    or towards who We are transferring or disclosing your Personal Data, for what period We are
                    processing your Personal Data, your rights in connection with Data Processing carried out by Us
                    regarding your Personal Data, your right to lodge a complaint with a supervisory authority regarding
                    the processing. Additionally, in the case We collect your Personal Data from other sources than from
                    You, any available information as to the source, and the existence of automated decision-making and
                    related information, including the logic involved, as well as the significance and the envisaged
                    consequences of such processing for You, whether your Personal Data is transferred outside the EEA,
                    and the conditions of these transfers.</BodyText>
                <BodyText>We will provide You with a copy of your personal Data processed by Us in case You require Us
                    to do so.</BodyText>
                <StrongText>
                    <UnorderedList role="list">right to rectification</UnorderedList>
                </StrongText>
                <BodyText>You have the right to request Us to rectify your inaccurate Personal Data and to request Us to
                    complete your incomplete Personal Data by means of providing Us with a supplementary
                    statement.</BodyText>
                <StrongText>
                    <UnorderedList role="list">right to erasure</UnorderedList>
                </StrongText>
                <ListHeading>We erase any of your Personal Data without delay if You request Us to do so in the event of
                    the following:</ListHeading>
                <ListHeading>1.</ListHeading>
                <OrderedList role="list">
                    <ListItem>Your Personal Data is no longer necessary for the concerning purpose;</ListItem>
                    <ListItem>You withdraw your consent and there is no other legal basis for the processing;</ListItem>
                    <ListItem>You object to the processing and there are no overriding legitimate grounds for the
                        processing;</ListItem>
                    <ListItem>Your Personal Data has been processed unlawfully;</ListItem>
                    <ListItem>Your Personal Data has to be erased according to relevant laws.</ListItem>
                </OrderedList>
                <BodyText><StrongText>Please note that We are entitled to not erase your Personal Data if it is
                    necessary - among others - for exercising the right of freedom of expression and information,
                    compliance with legal obligation, establishment, exercise or defense of legal
                    claims.</StrongText></BodyText>
                <StrongText>
                    <UnorderedList role="list">right to restriction of processing</UnorderedList>
                </StrongText>
                <BodyText><StrongText>You have the right to obtain from Us restriction of processing where one of the
                    following applies:</StrongText></BodyText>
                <BodyText><StrongText>1.</StrongText></BodyText>
                <OrderedList role="list">
                    <ListItem>You have contested the accuracy of your Personal Data, for a period enabling Us to verify
                        the accuracy of your Personal Data;</ListItem>
                    <ListItem>the processing is unlawful and You oppose the erasure of your Personal Data and request
                        the restriction of their use instead;</ListItem>
                    <ListItem>We need no longer your Personal Data for the purposes of the processing, but they are
                        required by You for the establishment, exercise or defense of legal claims.</ListItem>
                </OrderedList>
                <BodyText>Where processing has been restricted, such Personal Data shall, with the exception of storage,
                    only be processed with your consent or for the establishment, exercise or defense of legal claims,
                    or for the protection of the rights of another natural or legal person, or for reasons of important
                    public interest of the European Union or of a Member State.</BodyText>
                <StrongText>
                    <UnorderedList role="list">right to object to processing</UnorderedList>
                </StrongText>
                <BodyText>Where Personal Data is processed for direct marketing purposes, You have the right to object
                    at any time to the processing of Personal Data concerning You for such marketing, which includes
                    profiling to the extent that it is related to such direct marketing.</BodyText>
                <BodyText>You also generally have the right to object to the processing of Personal Data where the legal
                    basis of the processing activity is Our legitimate interest, or the legitimate interest of a third
                    party.</BodyText>
                <StrongText>
                    <UnorderedList role="list">right to data portability</UnorderedList>
                </StrongText>
                <BodyText>You have the right to receive your Personal Data, which You have provided to Us, in a
                    structured, commonly used and machine-readable format and have the right to transmit that data to
                    another controller without hindrance from Us. You also have the right to have your Personal Data
                    transmitted directly from Us to another controller, where technically
                    feasi<StrongText>ble.</StrongText></BodyText>
                <StrongText>
                    <UnorderedList role="list">right to withdraw your consent</UnorderedList>
                </StrongText>
                <BodyText>You have the right to withdraw your consent at any time, without affecting the lawfulness of
                    processing based on your consent before its withdrawal.</BodyText>
                <BodyText>We take actions requested in relation to exercising your above rights without undue delay and
                    in any event within one month of receipt of your request. This period may be extended by two months
                    where necessary, with a reasoned notification to You, taking into account the complexity and number
                    of requests.</BodyText>
                <BodyText>In the event when You make such a request by electronic means, We provide You with information
                    by electronic means where possible, unless You request otherwise.</BodyText>
                <BodyText>In case We do not take any action regarding your request, We will inform You as to the reasons
                    within one month of receipt of your request. We will take necessary actions regarding exercising
                    your rights in relation to the processing free of charge except when your request is manifestly
                    unfounded or excessive.</BodyText>
                <BodyText>In case We have reasonable doubts as to the identity of the natural person making the request,
                    We may request additional information necessary to confirm the identity of such person.</BodyText>
                <BodyHeading>V. Remedies</BodyHeading>
                <BodyText>In case You do not agree with our response or action or if You consider that your rights have
                    been infringed, You may lodge your complaint with the Information Commissioners Office (address:
                    Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF, web: <Anchor
                        href="https://ico.org.uk/global/contact-us/"><StrongText>https://ico.org.uk/global/contact-us/</StrongText></Anchor>).</BodyText>
                <BodyHeading>VI. Miscellaneous</BodyHeading>
                <BodyText>Please note that We review this Privacy Notice on occasions and amend it as necessary. When We
                    amend this Privacy Notice, We will announce and publish it by the usual means (e.g., via e-mail or
                    on the Website). We encourage You to review this Privacy Notice regularly.</BodyText>
            </Wrapper>
            </Container>
        </Layout>
    )
}

export default PrivayPolicy;
