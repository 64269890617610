import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Layout from '../Layout';

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: 'PT Sans', sans-serif;
  min-height: 100vh;
  margin-top: 70px;
  width: 100%;
  padding: 0% 0% 5% 0%;
  gap: 20px;
  @media (max-width: 780px) {
    flex-direction: column;
    width: 100%;
    align-self: center;
    padding: 10%;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 30%;
  @media (max-width: 780px) {
    text-align: center;
    margin: 0;
    width: 100%;
  }
`;
const Heading = styled.h1`
  font-size: 60px;
  letter-spacing: 1px;
  @media (max-width: 780px) {
    font-size: 50px;
  }
`;
const BodyText = styled.text`
  font-weight: 100;
  font-size: 22px;
  line-height: 35px;
`;
const SubText = styled.text`
  color: blue;
  letter-spacing: 4px;
  font-size: 15px;
  margin-bottom: 15px;
  @media (max-width: 780px) {
    margin-bottom: 0;
  }
`;
const BackgroundText = styled.text`
  font-size: 700px;
  font-weight: 600;
  opacity: 0.05;
  position: absolute;
  margin-left: 20%;
  margin-top: -5%;
  letter-spacing: 20px;
  top: 0;
  pointer-events: none;
  @media (max-width: 780px) {
    font-size: 500px;
    position: fixed;
    margin: 0% -100% 0% 0%;
  }
`;
const Image = styled.img`
  width: 450px;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
const ErrorPage = () => {
  return (
    <Layout variant="white">
      <Wrapper>
        <BackgroundText>40</BackgroundText>
        <Section>
          <SubText>404 ERROR</SubText>
          <Heading>Sorry, we couldn't find the page you are looking for.</Heading>
          <BodyText>We can take you to our <Link to="/" style={{color: "black"}}>homepage</Link> or you can also use the navigation bar or footer to get to your destination.</BodyText>
        </Section>
        <Section><Image src="illustration.png" width="450px" alt="illustration" /></Section>
      </Wrapper>
    </Layout>
  );
}

export default ErrorPage;