import React from "react";
import Layout from "../Layout/index";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Images from "../../Constants/Images";

function Bitrise() {
  const imgData = [
    {
      _id: 1,
      image: Images.Bitrise5,
    },
    {
      _id: 2,
      image: Images.Bitrise6,
    },
    {
      _id: 3,
      image: Images.Bitrise7,
    },
    {
      _id: 4,
      image: Images.Bitrise8,
    },
    {
      _id: 5,
      image: Images.Bitrise9,
    },
  ];
  const testing = [
    {
      _id: 1,
      image: Images.Bitrise1_2,
      name: "Full mobile stack coverage",
      desc:
        "Whether you swear by native or would rather go cross-platform, we have you covered. From Swift to Objective-C, Java to Kotlin, as well as Xamarin, Cordova, Ionic, React Native, and Flutter: Whatever you choose, we will automatically configure your initial workflows and have you building in minutes.",
    },
    {
      _id: 2,
      image: Images.Bitrise2,
      name: "Store your code wherever",
      desc:
        "Bitrise can work with any public, private, or ad hoc Git service. This includes GitHub, GitHub Enterprise, GitLab, GitLab Enterprise and Bitbucket, both in the cloud as well as on premise.",
    },
    {
      _id: 3,
      image: Images.Bitrise3,
      name: "For any part of the process",
      desc:
        "Trigger builds on pull requests, schedule them to run at specific times or create your own webhooks. Workflows will run how and when you need them, allowing you to string together common tasks like integration tests, deployment to device farms, distributions to testers or app stores, and more.",
    },
    {
      _id: 4,
      image: Images.Bitrise4,
      name: "No hardware required",
      desc:
        "Bitrise is full-featured mobile CI / CD in the cloud, for any platform. This means that, in addition to builds running on Linux machines, MacOS builds are also fully included in all plans, including those for free users, and open source projects. No expensive hardware required.",
    },
  ];
  const features = [
    {
      _id: 1,
      image: Images.Bitrise10,
      name: "Firebase",
    },
    {
      _id: 2,
      image: Images.Bitrise11,
      name: "Bitbucket snippet runner",
    },
    {
      _id: 3,
      image: Images.Bitrise12,
      name: "GitHub status",
    },
    {
      _id: 4,
      image: Images.Bitrise13,
      name: "Carthage",
    },
    {
      _id: 5,
      image: Images.Bitrise14,
      name: "Amazon S3 deploy",
    },
    {
      _id: 6,
      image: Images.Bitrise15,
      name: "Fastlane",
    },
    {
      _id: 7,
      image: Images.Bitrise16,
      name: "Gradle runner",
    },
    {
      _id: 8,
      image: Images.Bitrise17,
      name: "GitLab status",
    },
    {
      _id: 9,
      image: Images.Bitrise18,
      name: "Codecov",
    },
    {
      _id: 10,
      image: Images.Bitrise19,
      name: "Jira comments",
    },
    {
      _id: 11,
      image: Images.Bitrise20,
      name: "Cocoapods install",
    },
    {
      _id: 12,
      image: Images.Bitrise21,
      name: "Slack",
    },
  ];
  return (
    <>
      <Layout>
        <main id="page_content">
          <section
            className="mobile_first_banner"
            style={{
              backgroundImage: ` url(${Images.Bitrise23}),radial-gradient(circle farthest-corner at 50% 100%,#2d163f,#492f5c)`,
            }}
          >
            <Container>
              <Row>
                <Col lg={7} md={12} xs={12} className="align-self-center">
                  <h1>Build better mobile applications, faster</h1>
                  <p>
                    Save time, money, and developer frustration with
                    <strong>fast</strong>, <strong>flexible,</strong> and
                    <strong>scalable</strong> mobile CI / CD
                    <strong> that just works</strong>.
                  </p>
                  <div className="text-center d-flex justify-content-center">
                    <a href="#" className="btn btn_1">
                      Get Started for Free
                    </a>
                    <a href="#" className="btn btn_2">
                      Talk to the Team
                    </a>
                  </div>
                </Col>
                <Col lg={5} md={12} xs={12}>
                  <img src={Images.Bitrise24} alt="" className="img-fluid" />
                </Col>
              </Row>
            </Container>
          </section>
          <section className="mobile_first">
            <Container>
              <h1>
                Mobile-first features that help you build, test and deploy
                faster and more efficiently
              </h1>
              <Row>
                {testing.map((data, index) => (
                  <Col lg={6} md={6} xs={12} key={`id-${index}-${data._id}`}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col lg={3} md={3} xs={12}>
                            <img
                              src={data.image}
                              alt=""
                              className="img-fluid"
                            />
                          </Col>
                          <Col lg={9} md={9} xs={12}>
                            <h2>{data.name}</h2>
                            <p>{data.desc}</p>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
          <section
            className="sec_3 vw"
            style={{
              backgroundImage: ` url(${Images.Bitrise22}),radial-gradient(circle farthest-corner at 50% 100%,#2d163f,#492f5c)`,
            }}
          >
            <Container>
              <h4>Used by the world's most productive mobile teams</h4>
              <div className="div-block-25">
                {imgData.map((todo, index) => (
                  <img
                    src={todo.image}
                    alt=""
                    key={`id-${index}-${todo._id}`}
                  />
                ))}
              </div>

              <div className="text-center">
                <a href="#" className="btn">
                  Learn how Bitrise helped
                </a>
              </div>
            </Container>
          </section>
          <section className="manage_setup">
            <Container>
              <Row>
                <Col lg={6} md={6} xs={12} className="align-self-center">
                  <h1>
                    Setup and maintenance is a breeze with 300+ ready-made
                    steps.
                  </h1>
                  <p>
                    Bitrise offers more than 300 steps and integrations to
                    easily connect the processes and services you use in your
                    mobile development workflows.
                    <b>
                      Setup becomes a breeze and maintenance is drastically
                      reduced, saving you both time and money.
                    </b>
                    Steps are fully open sourced, allowing everyone to submit or
                    improve on steps at any time. Can’t find a step? Submit one
                    to the library, create a private step exclusively for your
                    organization, or simply insert custom code into workflows
                    where needed.
                  </p>
                  <a href="#" className="btn">
                    Explore all 300+ steps
                  </a>
                </Col>
                <Col lg={6} md={6} xs={12}>
                  <Row>
                    {features.map((data, index) => (
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        key={`id-${index}-${data._id}`}
                      >
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col xs={6} className="sv">
                                <img
                                  src={data.image}
                                  alt=""
                                  className="img-fluid"
                                />
                              </Col>
                              <Col xs={6} className="sv align-self-center">
                                <h5>{data.name}</h5>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="mobile">
            <Container>
              <h1>Ready for mobile CI / CD that just works?</h1>
              <p>Experience how Bitrise makes mobile development better</p>
              <div className="text-center">
                <a href="#" className="btn">
                  Get Started for Free
                </a>
              </div>
              <p className="want">
                Want to talk to another human about how we can help?
              </p>
              <div className="text-center">
                <a href="#">Get in touch</a>
              </div>
            </Container>
          </section>
        </main>
      </Layout>
    </>
  );
}

export default Bitrise;
