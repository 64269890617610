import React from 'react';
import styled from 'styled-components';
import Layout from '../Layout';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';

const Wrapper = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 1500px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 50% 5% 5%;
  height: 500px;
  width: 100vw;
  max-width: 1600px;
  gap: 20px;
  @media (max-width: 780px) {
    background-size: contain;
    padding: 5% 20% 5% 5%;
  }
  @media (max-width: 780px) {
    background-size: contain;
    padding: 5% 20% 5% 5%;
  }
`;
const Heading = styled.h1`
  font-weight: bold;
  ${props => {
    switch(props.color) {
      case 'black': return `color: black;`;
      case 'blue': return `color: #2D75BC;`;
      default: return `color: white`;
    }
  }}
`;
const BodyText = styled.p`
  font-weight: ${props => props.bold ? 800 : 500};
  color: ${props => props.black ? 'black' : 'white'};
  font-size: 18px;
`;
const Button = styled.button`
  background: ${props => props.blackbg ? 'black' : 'transparent'};
  border-radius: 100px;
  border: 1px solid white;
  white-space: nowrap;
  font-size: 20px;
  transition: 0.5s;
  padding: 10px;
  outline: 0;
  width: 250px;
  height: 60px;
  color: white;
  &:hover {
    color: ${props => props.blackbg ? 'black' : 'darkblue'};
    background: white;
  }
  &:focus {
    outline: 0;
  }
`;

const MyPal = () => {
  return (
    <Layout variant="white">
      <Wrapper>
        <Banner src="Banner1.jpg">
          <Heading color="white">MyPal - Personal Automation</Heading>
          <BodyText bold>All your content in one place</BodyText>
          <BodyText>Never forget to check apps your folks on Instagram, News, SnapChat, Medium Website,and looking at the message thread, you can create a group/flow of that, and have access to that content right on your fingertips.</BodyText>
          <Button blackbg>Download Now <HiOutlineArrowNarrowRight style={{ marginBottom: "3px" }}/></Button>
        </Banner>
        <Banner src="Banner2.jpg">
          <Heading color="black">MyPal - Personal Automation</Heading>
          <BodyText black bold>Social Media in one place</BodyText>
          <BodyText black>Never forget to check apps your folks on Instagram, News, SnapChat, Medium Website,and looking at the message thread, you can create a group/flow of that, and have access to that content right on your fingertips.</BodyText>
        </Banner>
        <Banner src="Banner3.jpg">
          <Heading color="white">MyPal - Personal Automation</Heading>
          <BodyText bold>Groups/Flows of anything you can think of!</BodyText>
          <BodyText>For music junkies have a “playlist” of songs, albums, artists, play lists, stations from all major music platforms like Apple Music, Spotify, SoundCloud, YouTube, Pandora, etc. on your fingertips, without having to go look for them.</BodyText>
        </Banner>
        <Banner src="Banner4.jpg">
          <Heading color="black">Amazing File Management</Heading>
          <BodyText black>Content file management system gives you the ability to easily store and organize pictures, videos, messages, documents, audio, and all other file types. Easily view these files in the appor for the purpose of sending to contacts and social media platforms of your choice quickly.</BodyText>
        </Banner>
        <img src="Banner5.png" alt="banner-5" width="1600px" />
        <Banner src="Banner6.jpg">
          <Heading color="black">MyPal - Personal Automation</Heading>
          <BodyText black bold>Text, Call, Email, Group, Text Contact & People</BodyText>
          <BodyText black>Easily create a group of for your friends and clients and easily reach them by email, phone, text, whatsapp or other messaging platform.</BodyText>
        </Banner>
        <Banner src="Banner7.jpg">
          <Heading color="blue">MyPal - Personal Automation</Heading>
          <BodyText>MyPal provides a revolutionary way to interact with your phone. Being your personal assistant and personal automation client for your contacts, apps, websites, social media and whatever else your heart wants. MyPal has hundreds of feature sf ororganizing and automating your daily, weekly, monthly activities maximizing your usage of your phone without having to compromise on time.</BodyText>
          <Button>Explore Now <HiOutlineArrowNarrowRight style={{ marginBottom: "3px" }}/></Button>
        </Banner>
        <Banner src="Banner8.jpg">
          <Heading color="white">MyPal - Personal Automation</Heading>
          <BodyText>MyPal provides a revolutionary way to interact with your phone. Being your personal assistant and personal automation client for your contacts, apps, websites, social media and whatever else your heart wants. MyPal has hundreds of feature sf ororganizing and automating your daily, weekly, monthly activities maximizing your usage of your phone without having to compromise on time.</BodyText>
          <Button>Explore Now <HiOutlineArrowNarrowRight style={{ marginBottom: "3px" }}/></Button>
        </Banner>
      </Wrapper>
    </Layout>
  );
}

export default MyPal;
