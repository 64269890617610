import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Context } from "../Context/globalContextProvider";
import { FaUser, FaCartPlus, FaSearch } from "react-icons/fa";
import {  BsFillCartFill } from "react-icons/bs";
import SubscribeNewsletter from "./SubscribeNewsletter";
import { cartClose, cartOpen } from "../../redux/actions/cartActions";

const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  // justify-content: space-around;
  @media (max-width: 680px) {
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }
  align-items: flex-center;
  height: max-content;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  position: fixed;
  padding-top: 4px;
  background: ${({ variant }) => (variant === "white" ? "white" : "#333333")};
  @media (max-width: 680px) {
    height: 50px;
  }
`;
const Button = styled.button`
  border: 0;
  outline: 0;
  height: 70px;
  transition: 0.5s;
  background: ${({ variant }) => (variant === "white" ? "white" : "#333333")};
  // text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  color: ${({ variant }) => (variant === "white" ? "#333333" : "white")};
  white-space: nowrap;
  border-bottom: ${({ variant }) =>
    variant === "white" ? "3px solid white" : "3px solid #333333"};
  &:hover {
    cursor: pointer;
    border-bottom: ${({ variant }) =>
      variant === "white" ? "3px solid #333333" : "3px solid white"};
  }
  &:focus {
    border: 0;
    outline: 0;
    border-bottom: ${({ variant }) =>
      variant === "white" ? "3px solid white" : "3px solid #333333"};
  }
  @media (max-width: 680px) {
    height: 40px;
    opacity: 0.9;
    justify-self: center;
  }
`;
const ActiveButton = styled(Button)`
  border-bottom: ${({ variant }) =>
    variant === "white" ? "3px solid #333333" : "3px solid white"};
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  padding-right: 20%;

  @media (max-width: 680px) {
    gap: 10px;
    flex-flow: column nowrap;
    background-color: ${({ variant }) =>
      variant === "white" ? "white" : "#333333"};
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 70%;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    padding-right: 0;
  }
`;
const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  z-index: 20;
  display: none;
  @media (max-width: 680px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    align-self: center;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ variant }) =>
      variant === "white" ? "#333333" : "white"};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
const tags = ["Techlabz Keybox"];
// const tags = ["Techlabz Keybox", "Shop", "Blogs", "Downloads"];
const links = ["/", "/products", "/all-blogs", "/drivers"];

const icons = [];
// const icons = ["Account", "Cart"];
const iconLinks = ["/", "#"];

const Header = (props) => {
  // const dispatch = useDispatch();
  // const { isCartOpen } = useSelector((state) => ({
  //   isCartOpen: state.cart.isCartOpen,
  // }));
  // const onClickCartIcon = () => {
  //   if (isCartOpen) {
  //     dispatch(cartClose());
  //   } else {
  //     dispatch(cartOpen());
  //   }
  // };

  const [modalShow, setModalShow] = React.useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [isClicked, setIsClicked] = useState(false);

  const { variant } = props;
  const user = React.useContext(Context);
  let logotext =
    variant === "white" ? (
      <Link
        to="/"
        style={{
          cursor: "pointer",
          paddingLeft: "10%",
          paddingRight: "10%",
          alignSelf: "center",
        }}
      >
        <img src="techlabz-sideblack.png" alt="techlabz-logo" width="180px" />
      </Link>
    ) : (
      <Link
        to="/"
        style={{
          cursor: "pointer",
          paddingLeft: "10%",
          paddingRight: "10%",
          alignSelf: "center",
        }}
      >
        <img src="techlabz-sidewhite.png" alt="techlabz-logo" width="180px" />
      </Link>
    );

  useEffect(() => {
    if (isClicked === true) {
      if (open) {
        setOpen(false);
      }
      setIsClicked(false);
    }
  }, [isClicked]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClicked(true);
      } else {
        setIsClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const onClickNavItem = (label) => {
    if (label === "Shop") {
      setModalShow(true);
    } else {
      setModalShow(false);
    }

    if (label === "Cart") {
      // onClickCartIcon();
    } else {
      // dispatch(cartClose());
    }
  };

  return (
    <>
      <Wrapper variant={variant}>
        <StyledBurger
          variant={variant}
          open={open}
          onClick={() => setOpen(!open)}
        >
          <div />
          <div />
          <div />
        </StyledBurger>
        {logotext}
        <ButtonGroup ref={ref} open={open} variant={variant}>
          {tags.map((label, index) => {
            return (
              <Link
                to={links[index]}
                style={{ textDecoration: "none" }}
                key={`id-${index}`}
              >
                {index === user.activeTab ? (
                  <ActiveButton
                    onClick={() => {
                      user.setActiveTab(index);
                      setOpen(false);
                      onClickNavItem(label);
                    }}
                    variant={variant}
                    key={index}
                  >
                    {label}
                    {label === "Techlabz Keybox" ? (
                      <span>&trade;</span>
                    ) : null}
                  </ActiveButton>
                ) : (
                  <Button
                    onClick={() => {
                      user.setActiveTab(index);
                      setOpen(false);
                      onClickNavItem(label);
                    }}
                    variant={variant}
                    key={index}
                  >
                    {label}
                    {label === "Techlabz Keybox" ? (
                      <span>&trade;</span>
                    ) : null}
                  </Button>
                )}
              </Link>
            );
          })}
          {icons.map((label, index) => {
            return (
              <Link
                to={iconLinks[index]}
                style={{ textDecoration: "none" }}
                key={`id-${index}`}
              >
                {index + tags.length === user.activeTab ? (
                  <ActiveButton
                    onClick={() => {
                      user.setActiveTab(index + tags.length);
                      setOpen(false);
                      onClickNavItem(label);
                    }}
                    variant={variant}
                    key={index}
                  >
                    {label === "Account" ? ( <FaUser /> ) :  ( <BsFillCartFill /> )}
                  </ActiveButton>
                ) : (
                  <Button
                    onClick={() => {
                      user.setActiveTab(index + tags.length);
                      setOpen(false);
                      onClickNavItem(label);
                    }}
                    variant={variant}
                    key={index}
                  >
                    {label === "Account" ? ( <FaUser /> ) :  ( <BsFillCartFill /> )}
                  </Button>
                )}
              </Link>
            );
          })}
        </ButtonGroup>
      </Wrapper>
      <SubscribeNewsletter
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Header;
