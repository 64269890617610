import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
// import Socials from "./Socials";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const Layout = (props) => {
  const { variant, children } = props;
  return (
    <Wrapper>
      <Header variant={variant} />
      {children}
      <Footer />
      {/* <Socials /> There are floating social media icon*/}
    </Wrapper>
  );
};

export default Layout;
